
import UiHelper from '_utils/UiHelper';

class JobMonitor {
  constructor(processKey, timeLimit, onTimeout) {
    this.processKey = processKey;
    this.timeLimit = timeLimit;
    this.onTimeout = onTimeout;
    this.timeout = null;

    // Debounced update function to prevent excessive resetting
    this.debouncedUpdate = UiHelper.debounce(this.update.bind(this), 500);

    this.startMonitoring();
  }

  startMonitoring() {
    this.resetTimeout();
  }

  update() {
    this.resetTimeout();
  }

  resetTimeout() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onTimeout(this.processKey);
    }, this.timeLimit);
  }

  stopMonitoring() {
    clearTimeout(this.timeout);
  }
}

export default JobMonitor;
