
import DynamicTooltipIcon                       from '_views/shared/DynamicTooltipIcon';
import EventManager                             from '@brainscape/event-manager';
import IconTextButton                           from '_views/shared/IconTextButton';
import React, { useState, useEffect, useRef }   from 'react';
import SimpleProgressBar                        from '_views/shared/SimpleProgressBar';

import { toClassStr }                           from '_utils/UiHelper';
import { useFadeTransition }                    from "_hooks/useFadeTransition";

import {
  DismissButton,
  DragButton, 
}                                               from '_views/shared/IconButton';

import { 
  ExternalLinkIconTextButton,
}                                               from '_views/shared/IconTextButton';



const StatusBar = ({ 
  addClasses, 
  isMobileViewportSize, 
  isOpen, 
  onFadeOutComplete, 
  processes,
  transitionDuration = 500, 
}) => {

  let _isMounted = false;
    
  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const { ref, style, shouldRender } = useFadeTransition(isOpen, transitionDuration, onFadeOutComplete);

  // on Mount
  useEffect(() => {
    _isMounted = true;

    // on Unmount
    return () => {
      _isMounted = false;
    };
    
  }, []);
  

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleCloseButtonClick = () => {
    triggerCloseRequest();
  };

  const handleCompleteActionLinkClick = (processKey) => {
    if (_isMounted) {
      triggerRemoveProcessRequest(processKey);
    }
  }

  const handleErrorActionLinkClick = (processKey) => {
    if (_isMounted) {
      triggerRemoveProcessRequest(processKey);
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerCloseRequest = () => {
    EventManager.emitEvent('status-bar:close-request', {});
  };

  const triggerRemoveProcessRequest = (processKey) => {
    EventManager.emitEvent('status-bar:remove-process-request', {
      processKey: processKey,
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderDragButton = () => {
    return null; 

    if (isMobileViewportSize) {
      return null;
    }

    return (
      <DragButton
        addClasses="drag-bar-button"
      />
    );
  };

  const renderProcessStatusBlocks = () => {
    if (!processes || Object.keys(processes).length < 1) {
      return null;
    }

    return Object.keys(processes).map((processKey, index) => {
      const process = processes[processKey];
      return renderProcessStatusBlock(process);
    });
  };

  const renderProcessStatusBlock = (process) => {
    if (!process.isShowing) {
      return null;
    }

    if (process.status == "complete") {
      return renderProcessCompleteStatusBlock(process);
    }

    if (process.status == "error") {
      return renderProcessErrorStatusBlock(process);
    }

    return (
      <div className="process-status-block" key={process.key}>

        <div className="message" title={process.message}>{process.message}</div>

        <div className="second-line">
          {renderSubLabel(process.subLabel)}
          {renderProgressCount(process)}
        </div>

        {renderProgressBar(process)}
      </div>
    );
  };

  const renderProcessCompleteStatusBlock = (process) => {
    return (
      <div className="process-status-block complete" key={process.key}>
        <div className="message" title={process.message}>{process.message}</div>

        <div className="second-line">
          <IconTextButton
            addClasses="action-link"
            iconClass="ion-ios-arrow-forward"
            iconPosition="right"
            isLink={true}
            label={process.actionLinkLabel}
            linkUrl={process.actionLinkUrl}
            onClick={() => handleCompleteActionLinkClick(process.key)}
          />
        </div>

        {renderProgressBar(process)}
      </div>
    );
  };

  const renderProcessErrorStatusBlock = (process) => {
    return (
      <div className="process-status-block error" key={process.key}>

        <div className="message-and-tooltip">
          <div className="message" title={process.message}>{process.message}</div>
          {renderErrorMessageDetailTooltip(process)}
        </div>

        <div className="second-line">
          <ExternalLinkIconTextButton
            addClasses="action-link"
            iconPosition="right"
            isLink={true}
            label={process.actionLinkLabel}
            linkUrl={process.actionLinkUrl}
            onClick={() => handleErrorActionLinkClick(process.key)}
          />

          {renderProgressCount(process)}
        </div>
        
        {renderProgressBar(process)}
      </div>
    );
  };

  const renderErrorMessageDetailTooltip = (process) => {
    if (!process.messageDetail) {
      return null;
    }

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="error-detail-tooltip"
        body={process.messageDetail}
        hasDismissButton={true}
        heading={'Error Detail'}
        position="top"
        subHeading={null}
      />
    );
  }

  const renderProgressCount = (process) => {
    return (
      <div className="count">{`${process.progressIndex}/${process.progressTotal}`}</div>
    );
  }

  const renderProgressBar = (process) => {
    return (
      <SimpleProgressBar
        baseValue={process.progressTotal}
        barValue={process.progressIndex}
      />
    );
  }

  const renderSubLabel = (subLabel) => {
    if (!subLabel) {
      return null;
    }

    return <div className="sub-label" title={subLabel}>{subLabel}</div>;
  };


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  if (!shouldRender) {
    return null;
  }

  const classes = toClassStr(['status-bar', addClasses]);

  return (
    <div className={classes} ref={ref} style={style}>

      <div className="header">
        {renderDragButton()}
      </div>

      <div className="process-status-blocks">
        {renderProcessStatusBlocks()}
      </div>

      <div className="footer">
        <DismissButton
          onClick={handleCloseButtonClick}
        />
      </div>
    </div>
  );
};

export default StatusBar;
