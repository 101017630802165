
import AiExceptionPanel                 from '_views/modals/ai/AiExceptionPanel';
import BruceWaitPanel                   from '_views/modals/ai/BruceWaitPanel';
import ChooseDeckPanel                  from '_views/modals/ai/ChooseDeckPanel';
import EventManager                     from '@brainscape/event-manager';
import GenerateFromTopicPanel           from '_views/modals/ai/GenerateFromTopicPanel';
import ImportPasteFlashcardsPanel       from '_views/modals/ai/ImportPasteFlashcardsPanel';
import Modal                            from '_views/shared/Modal';
import PasteTextPanel                   from '_views/modals/ai/PasteTextPanel';
import React                            from 'react';
import ReviewCardRowsPanel              from '_views/modals/ai/ReviewCardRowsPanel';
import SelectMethodPanel                from '_views/modals/ai/SelectMethodPanel';
import StringHelper                     from '_utils/StringHelper';
import SummarizeFromContentPanel        from '_views/modals/ai/SummarizeFromContentPanel';
import UploadFilePanel                  from '_views/modals/ai/UploadFilePanel';

import { toClassStr }                   from '_utils/UiHelper';

const DROPPABLE_PANELS = ['importPasteFlashcards', 'summarizeFromContent'];


const MakeFlashcardsModal = ({ 
  acceptedFileTypes,
  cardRows, 
  createMethod, 
  currentPanel,
  currentParserName,
  currentUser,
  deckOptions,
  errorMessage,
  generatedDeckMetadata,
  hasInvalidInput,
  initialInputText,
  initialInputTopicData,
  initialNewDeckDesc,
  initialNewDeckName,
  isAiParsable, 
  isAiParseComplete,
  isBackable,
  isDirectToCards=false,
  isProcessing, 
}) => {
  
  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleBackButtonClick = () => {
    triggerGoBackRequest();
  };

  const handleFileDragEnter = () => {
    switch (currentPanel) {
      case 'importPasteFlashcards':
        triggerUploadImportFileRequest();
      break;
      case 'summarizeFromContent':
        triggerUploadSummarizeFileRequest();
      break;
    }
  }

  const handleModalClose = () => {
    triggerModalCloseRequest();
  };

  const handleModalClosed = () => {
    console.log('Modal closed');
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerGoBackRequest = () => {
    EventManager.emitEvent('make-flashcards-modal:back-request', {});
  }

  const triggerModalCloseRequest = () => {  
    EventManager.emitEvent('make-flashcards-modal:close', {});
  }

  const triggerUploadImportFileRequest = () => {
    EventManager.emitEvent('import-paste-flashcards-panel:upload-file-request', {});
  }

  const triggerUploadSummarizeFileRequest = () => {
    EventManager.emitEvent('summarize-from-content-panel:upload-file-request', {});
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const getPanelTag = () => {
    const baseTag = StringHelper.toKebabCase(currentPanel);
    return `${baseTag}-panel`;
  }


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderModalContent = () => {
    switch (currentPanel) {
      case 'freeAiException':
        return (
          <AiExceptionPanel 
            exceptionType={'freeAiException'}
          />
        );
      case 'generalAiException':
        return (
          <AiExceptionPanel
            errorMessage={errorMessage}
            exceptionType={'generalAiException'}
          />
        ); 
      case 'selectMethod':
        return (
          <SelectMethodPanel 
            isDirectToCards={isDirectToCards}
          />
        );
      case 'loading':
        return (
          <BruceWaitPanel 
            isAiParseComplete={isAiParseComplete}
          />
        );
      case 'pasteText':
        return (
          <PasteTextPanel
            createMethod={createMethod}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            initialInputText={initialInputText}
            isProcessing={isProcessing}
          />
        );
      case 'proAiException':
        return (
          <AiExceptionPanel 
            exceptionType={'proAiException'}
          />
        );
      case 'review':
        return (
          <ReviewCardRowsPanel 
            cardRows={cardRows}
            currentParserName={currentParserName}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isAiParsable={isAiParsable}
            isProcessing={isProcessing}
          />
        );
      case 'aiReview':
        return (
          <ReviewCardRowsPanel 
            cardRows={cardRows}
            currentParserName={currentParserName}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isAiParsable={isAiParsable}
            isProcessing={isProcessing}
          />
        );
      case 'generateFromTopic':
        return (
          <GenerateFromTopicPanel
            currentUser={currentUser}
            initialInputTopicData={initialInputTopicData}
          />
        );
      case 'summarizeFromContent':
        return (
          <SummarizeFromContentPanel
            acceptedFileTypes={acceptedFileTypes}
            currentUser={currentUser}
          />
        );
      case 'uploadFile':
        return (
          <UploadFilePanel
            acceptedFileTypes={acceptedFileTypes}
            createMethod={createMethod}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isProcessing={isProcessing}
          />
        );
      case 'importPasteFlashcards':
        return (
          <ImportPasteFlashcardsPanel
            acceptedFileTypes={acceptedFileTypes}
          />
        );
        case 'chooseDeck':
          return (
            <ChooseDeckPanel
              currentUser={currentUser}
              deckOptions={deckOptions}
              generatedDeckMetadata={generatedDeckMetadata}
              initialNewDeckDesc={initialNewDeckDesc}
              initialNewDeckName={initialNewDeckName}
              isProcessing={isProcessing}
            />
          );
        default:
          return null;
    }
  };


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const isDroppable = (DROPPABLE_PANELS.includes(currentPanel));
  // const isSelectMethodPanel = (currentPanel == 'selectMethod' ? 'select-method-panel' : '');
  const panelTag = getPanelTag() || '';
  const mediumModalClass = (['review', 'aiReview'].includes()) ? 'is-medium-modal' : 'is-medium-modal';
  const classes = toClassStr(['make-flashcards-modal', panelTag, mediumModalClass]);

  return (
    <Modal 
      addClasses={classes}
      isDroppable={isDroppable}
      isOpen={true} 
      onClosed={handleModalClosed}
      onCloseRequest={handleModalClose}
      onFileDragEnter={handleFileDragEnter}
      onBackIconButtonClick={handleBackButtonClick}
      shouldShowBackButton={isBackable}
      shouldShowDragStates={false}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default MakeFlashcardsModal;
