
import { apiPost } from '_core/Api2';
import DelayedResultsHandler from '_utils/DelayedResultsHelper';


const PARSING_TIME_LIMIT = (1000 * 60 * 3.5); // 3.5 minutes


const AiParseTopicService = {
  parse: function(body) {
    const params = {
      mimeType: 'text/plain',
      body: JSON.stringify(body),
    };

    return apiPost('ai/topic', params)
      .then(function(rs) {
        console.log('AiParseTopicService.parse() response:', rs);

        return DelayedResultsHandler.handleDelayedResults(rs, PARSING_TIME_LIMIT)
          .then(function(delayedResult) {
            if (delayedResult && delayedResult.cards) {
              return delayedResult.cards;
            } else {
              return Promise.reject(new Error('No cards found in the result.'));
            }
          })
          .catch(function(err) {
            return Promise.reject(err);
          });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },
};

export default AiParseTopicService;
