
import { trackEvent }                           from '_services/AnalyticsService';

import AiCardListMetadataService                from '_services/AiCardListMetadataService';
import AiParseListService                       from '_services/AiParseListService';
import AiParseDocumentService                   from '_services/AiParseDocumentService';
import AiParseTopicService                      from '_services/AiParseTopicService';
import StandardParseListService                 from '_services/StandardParseListService';

import ArrayBufferService                       from '_services/ArrayBufferService';
import deckCardImport                           from '_models/deckCardImport';
import EventManager                             from '@brainscape/event-manager';
import FileHelper                               from '_utils/FileHelper';
import MakeFlashcardsModal                      from '_views/modals/ai/MakeFlashcardsModal';
import packDeck                                 from '_models/packDeck';
import packDeckCardImport                       from '_models/packDeckCardImport';
import React, { useState, useEffect, useRef }   from 'react';


const ACCEPTED_FILE_TYPES = {
  importPasteFlashcards: ['.csv', '.txt', '.xlsx', '.ods'], 
  summarizeFromContent: ['.docx', '.pptx', '.odt', '.odp', '.txt'],
};
const AI_PARSABLE_FILE_TYPES = {
  importPasteFlashcards: ['.csv', '.txt'], 
  summarizeFromContent: ['.docx', '.pptx', '.odt', '.odp', '.txt'],
};

// TODO: Checking for the following error messages is a temporary solution. We should have a more robust way to check for these errors.
const AI_TOKEN_EXCEPTION_FREE_USER_MSG = 'You have exceeded your free AI token quota.';
const AI_TOKEN_EXCEPTION_PRO_USER_MSG = 'You have exceeded your daily AI token quota.';

const PROGRESS_BAR_COMPLETION_DELAY = 3500;

const BACKABLE_PANELS = ['importPasteFlashcards', 'summarizeFromContent', 'generateFromTopic', 'pasteText', 'uploadFile', 'review', 'aiReview', 'nameDeck', 'chooseDeck'];
const HISTORY_PANELS = ['selectMethod', 'importPasteFlashcards', 'summarizeFromContent', 'generateFromTopic', 'pasteText', 'uploadFile', 'review', 'aiReview', 'nameDeck', 'chooseDeck'];

const CREATE_METHOD_LABELS = {  // for analytics
  importPasteFlashcards: 'importer',
  summarizeFromContent: 'summarize',
  generateFromTopic: 'tell-ai',
};

const CREATE_METHOD_DEFAULT_PARSER_NAMES = {
  importPasteFlashcards: 'standardParseList',
  summarizeFromContent: 'aiParseDocument',
  generateFromTopic: 'aiParseTopic',
};


let progressBarTimeout = null;


const MakeFlashcardsModalController = ({
  currentDecks,
  currentPackId,
  currentUser,
}) => {  
  /*
  ==================================================
   INIT 
  ==================================================
  */

  const eventManager = new EventManager();
  let _isMounted = false;


  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [currentPanel, setCurrentPanel] = useState(''); // selectMethod, importPasteFlashcards, summarizeFromContent, generateFromTopic, pasteText, uploadFile, url, review, aiReview, nameDeck, chooseDeck, loading, freeAiException, proAiException, generalAiException
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const deckOptions = useRef([]);
  const cardRows = useRef([]);
  const createMethod = useRef(null);
  const currentPackIdRef = useRef(currentPackId);
  const currentPanelRef = useRef(currentPanel);
  const currentDeckId = useRef(null);
  const errorMessage = useRef('');
  const generatedDeckMetadata = useRef({});
  const initialPanel = useRef(null);
  const inputBufferData = useRef({});
  const newDeckDesc = useRef('');
  const newDeckName = useRef('');
  const inputMethod = useRef(null);
  const inputText = useRef('');
  const inputTopicData = useRef({});
  const inputUrl = useRef('');
  const isAiParseProcessing = useRef(false);
  const isAiParseComplete = useRef(false);
  const isDirectToCards = useRef(false);
  const parserName = useRef(null);
  const panelHistory = useRef([]);

  
  // on Mount
  useEffect(() => {
    _isMounted = true;
    clearTimeout(progressBarTimeout);

    /* SUBSCRIBE TO EVENTS */
    eventManager.addListener('make-flashcards-modal:open', handleOpenModal);
    eventManager.addListener('make-flashcards-modal:close', handleCloseModal);
    eventManager.addListener('make-flashcards-modal:type-flashcards-request', handleTypeFlashcardsRequest);
    eventManager.addListener('make-flashcards-modal:import-paste-flashcards-request', handleImportPasteFlashcardsRequest);
    eventManager.addListener('make-flashcards-modal:summarize-from-content-request', handleSummarizeFromContentRequest);
    eventManager.addListener('make-flashcards-modal:generate-from-topic-request', handleGenerateFromTopicRequest);
    eventManager.addListener('make-flashcards-modal:back-request', handleBackRequest);

    eventManager.addListener('import-paste-flashcards-panel:paste-text-request', handlePasteTextRequest);
    eventManager.addListener('import-paste-flashcards-panel:upload-file-request', handleUploadFileRequest);
    
    eventManager.addListener('paste-text-panel:input-text-submit', handleInputTextSubmit);
    eventManager.addListener('paste-text-panel:back-request', handleBackRequest);

    eventManager.addListener('upload-file-panel:input-file-submit', handleInputFileSubmit);
    eventManager.addListener('upload-file-panel:back-request', handleBackRequest);
    eventManager.addListener('upload-file-panel:file-selected', handleFileSelected);
    eventManager.addListener('upload-file-panel:file-selection-error', handleFileSelectionError);

    eventManager.addListener('review-card-rows-panel:add-cards-request', handleAddCardsRequest);
    eventManager.addListener('review-card-rows-panel:fix-with-ai-request', handleFixWithAiRequest);
    eventManager.addListener('review-card-rows-panel:cancel-import-request', handleCancelRequest);

    eventManager.addListener('summarize-from-content-panel:paste-text-request', handlePasteTextRequest);
    eventManager.addListener('summarize-from-content-panel:upload-file-request', handleUploadFileRequest);

    eventManager.addListener('generate-from-topic-panel:input-topic-submit', handleInputTopicSubmit); 
    eventManager.addListener('generate-from-topic-panel:cancel-topic-request', handleCancelRequest); 

    eventManager.addListener('choose-deck-panel:existing-deck-submit', handleCardsToExistingDeckSubmit);
    eventManager.addListener('choose-deck-panel:new-deck-metadata-submit', handleCardsToNewDeckSubmit);
    eventManager.addListener('choose-deck-panel:suggest-deck-metadata-request', handleSuggestDeckMetadataRequest);


    // on Unmount
    return () => {
      eventManager.disable();
      clearTimeout(progressBarTimeout);
      _isMounted = false;
    };
  }, []);

  useEffect(() => {
    currentPanelRef.current = currentPanel;
  }, [currentPanel]);  

  useEffect(() => {
    currentPackIdRef.current = currentPackId;
  }, [currentPackId]);
  
  // on mount
  useEffect(() => {
    if (currentDecks) {
      deckOptions.current = getDeckOptions(currentDecks);
    }
  }, []);  

  useEffect(() => {
    if (currentDecks && isModalOpen) {
      deckOptions.current = getDeckOptions(currentDecks);
    }
  }, [currentDecks, isModalOpen]);  

  useEffect(() => {
    if (isModalOpen) {
      trackEvent(getCreateMethodLabel(), `view/${currentPanel}`);
    }
  }, [currentPanel, isModalOpen]);
    
  useEffect(() => {
    if (currentPanel !== 'generalAiException') {
      errorMessage.current = '';
    }
  }, [currentPanel]);


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAddCardsRequest = (eventData) => {
    if (currentDeckId.current) {
      addCardsToDeck();
    } else {
      newDeckName.current = getDeckName();

      if (deckOptions.current && deckOptions.current?.length > 0) {
        // give user a chance to add new cards to an existing deck or to a new one
        takePanelSnapshot();
        navigateToNextPanel('chooseDeck');
        trackEvent(getCreateMethodLabel(), 'choose deck');
      } else {
        // this is the first deck in the pack, go right to creating a new one
        addCardsAndDeckToPack(newDeckName.current, newDeckDesc.current);
      }
    }
  }

  const handleAiParseCompleted = () => {
    clearTimeout(progressBarTimeout);

    // let the loading panel animate to 100% before showing the review panel
    progressBarTimeout = setTimeout(() => {
      takePanelSnapshot();
      navigateToNextPanel('aiReview');
      isAiParseComplete.current = true;

      clearTimeout(progressBarTimeout);
    }, PROGRESS_BAR_COMPLETION_DELAY);
  }

  const handleAiParseError = (err) => {
    const errMessage = err.message;

    if (errMessage.indexOf(AI_TOKEN_EXCEPTION_FREE_USER_MSG) != -1) {
      navigateToNextPanel('freeAiException');
      trackEvent(getCreateMethodLabel(), 'ai/quota_exceeded', {pro: false});
      return true;
    }

    if (errMessage.indexOf(AI_TOKEN_EXCEPTION_PRO_USER_MSG) != -1) {
      navigateToNextPanel('proAiException');
      trackEvent(getCreateMethodLabel(), 'ai/quota_exceeded', {pro: true});
      return true;
    }

    errorMessage.current = errMessage;
    isAiParseProcessing.current = false;
    
    navigateToNextPanel('generalAiException');
    trackEvent(getCreateMethodLabel(), 'ai/general_ai_exception', {
      err: errorMessage.current,
    });
  }

  const handleAiParseRequest = (bufferData=inputBufferData.current) => {
    takePanelSnapshot();
    parseInputBufferData(bufferData);
  }

  const handleBackRequest = () => {
    const prevPanel = tryPreviousPanel();

    if (!prevPanel) {
      console.log('Bad Back Request');
    }
  }

  const handleCancelRequest = () => {
    trackEvent(getCreateMethodLabel(), 'cancel/exit');
    clearHistory();
    setIsModalOpen(false);
  }

  const handleCardsGenerated = () => {
    if (_isMounted) {
      setIsModalOpen(false);
      clearHistory();  
    }
  };

  const handleCardsToExistingDeckSubmit = (eventData) => {
    currentDeckId.current = eventData.deckId;

    takePanelSnapshot();
    setIsProcessing(true);
    addCardsToDeck();
  }

  const handleCardsToNewDeckSubmit = (eventData) => {
    newDeckDesc.current = eventData.newDeckDesc;
    newDeckName.current = eventData.newDeckName;

    takePanelSnapshot();
    setIsProcessing(true);
    addCardsAndDeckToPack(newDeckName.current, newDeckDesc.current);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    trackEvent(getCreateMethodLabel(), 'cancel/exit');
    clearHistory();
  };

  const handleFileSelected = () => {
    setHasInvalidInput(false);
    errorMessage.current = '';
  }

  const handleFileSelectionError = (eventData) => {
    setHasInvalidInput(true);
    errorMessage.current = eventData.errorMessage;
  }

  const handleFixWithAiRequest = () => {
    parserName.current = 'aiParseList';
    handleAiParseRequest(inputBufferData.current);
  }

  const handleGenerateFromTopicRequest = () => {
    takePanelSnapshot();
    createMethod.current = 'generateFromTopic';
    parserName.current = 'aiParseTopic';
    navigateToNextPanel('generateFromTopic');
  }

  const handleImportPasteFlashcardsRequest = () => {
    takePanelSnapshot();
    createMethod.current = 'importPasteFlashcards';
    parserName.current = 'standardParseList';
    navigateToNextPanel('importPasteFlashcards');
  }

  const handleInputFileSubmit = (eventData) => {
    takePanelSnapshot();
    setIsProcessing(true);

    ArrayBufferService.acceptFile(eventData.inputFile).then((bufferData) => {
      inputBufferData.current = bufferData;

      trackEvent(getCreateMethodLabel(), 'choose_file', {
        name: bufferData.fileName,
        size: bufferData.arrayBuffer?.byteLength,
      });

      parseInputBufferData(bufferData);

    }).catch((err) => {
      trackEvent(getCreateMethodLabel(), 'choose_file', { error: true });
      handleProcessingError(err);
    });
  };

  const handleInputTextSubmit = (eventData) => {
    setIsProcessing(true);
    inputText.current = eventData.inputText;
    takePanelSnapshot();

    ArrayBufferService.acceptText(eventData.inputText).then((bufferData) => {
      inputBufferData.current = bufferData;

      parseInputBufferData(bufferData);

    }).catch((err) => {
      handleProcessingError(err);
    });
  };

  const handleInputTopicSubmit = (eventData) => {
    inputTopicData.current = eventData;
    takePanelSnapshot();

    parseInputTopicData(eventData);
  }

  const handleOpenModal = (eventData) => {  // keys: panel, deckId, packId, isDirectToCards
    clearHistory();
    resetInputData();

    navigateToNextPanel(eventData.panel || 'selectMethod');
    createMethod.current = eventData.panel || null;
    initialPanel.current = eventData.panel || 'selectMethod';
    parserName.current = getDefaultParserName();
    isDirectToCards.current = eventData.isDirectToCards || false;   
    currentDeckId.current = eventData.deckId || null;

    setIsProcessing(false);
    setHasInvalidInput(false);
    setIsModalOpen(true);
  };

  const handlePasteTextRequest = () => {
    takePanelSnapshot();

    inputMethod.current = 'text';
    navigateToNextPanel('pasteText');
  }

  const handleProcessingError = (err) => {
    if (isAiParseProcessing.current) {
      handleAiParseError(err);
    }

    errorMessage.current = `Could not parse the input data: ${err.message}.`;
    setIsProcessing(false);
    setHasInvalidInput(true);
  }

  const handleSuggestDeckMetadataRequest = () => {
    isAiParseProcessing.current = true;
    navigateToNextPanel('loading');

    AiCardListMetadataService.generate(cardRows.current)
      .then(generatedMetadata => {
        setHasInvalidInput(false); 
        setIsProcessing(false);
        isAiParseProcessing.current = false;
        newDeckDesc.current = generatedMetadata.description || '';
        newDeckName.current = generatedMetadata.name || '';
        generatedDeckMetadata.current = generatedMetadata;
        takePanelSnapshot();

        navigateToNextPanel('chooseDeck');
      })
      .catch(err => {
        console.log('Error in handleSuggestDeckMetadataRequest:', err);
        handleProcessingError(err);
      });
  }

  const handleSummarizeFromContentRequest = () => {
    takePanelSnapshot();
    createMethod.current = 'summarizeFromContent';
    parserName.current = 'aiParseDocument';
    navigateToNextPanel('summarizeFromContent');
  }

  const handleTypeFlashcardsRequest = () => {
    if (!currentDeckId.current) {
      createUntitledDeck()
        .then((rs) => {
          currentDeckId.current = rs.deck.deckId;
          triggerEditDeckRequest();
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      triggerEditDeckRequest();
    }
  }
  
  const handleUploadFileRequest = () => {
    takePanelSnapshot();
    setHasInvalidInput(false);
    inputMethod.current = 'file';
    navigateToNextPanel('uploadFile');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerEditDeckRequest = () => {
    EventManager.emitEvent('deck-detail-view:change-request', {
      deckId: currentDeckId.current,
      packId: currentPackIdRef.current,
      tabId: 'edit',
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  const publishDeckCardsGenerated = (packId, deckId, cardCount) => {
    EventManager.emitEvent('deck-cards:generated', {
      deckId: deckId,
      packId: packId,
      newCardCount: cardCount,
    })
  }
  
  const publishPackDeckCardsGenerated = (packId, deckId, cardCount) => {
    EventManager.emitEvent('pack-deck-cards:generated', {
      deckId: deckId,
      packId: packId,
      newCardCount: cardCount,
    })
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const addCardsAndDeckToPack = (deckName=null, deckDesc=null) => {
    const shouldSuppressModelPublish = true;
    setIsProcessing(true);

    packDeckCardImport.create(currentPackIdRef.current, cardRows.current, deckName, deckDesc, shouldSuppressModelPublish)
      .then((rs) => {
        trackEvent(getCreateMethodLabel(), 'add', {
          ct: cardRows.current.length, // ct = cardCount
          packId: currentPackIdRef.current,
          deckId: rs.deckId,
        });

        publishPackDeckCardsGenerated(currentPackIdRef.current, rs.deckId, cardRows.current.length);
        handleCardsGenerated();
      })
      .catch(err => {
        console.error(err);
      });
  }

  const addCardsToDeck = () => {
    const shouldSuppressModelPublish = true;
    setIsProcessing(true);

    deckCardImport.create(currentPackIdRef.current, currentDeckId.current, cardRows.current, shouldSuppressModelPublish)
      .then((rs) => {
        trackEvent(getCreateMethodLabel(), 'add', {
          ct: cardRows.current.length, // ct = cardCount
          packId: currentPackIdRef.current,
          deckId: currentDeckId.current,
        }); 

        publishDeckCardsGenerated(currentPackIdRef.current, currentDeckId.current, cardRows.current.length);
        handleCardsGenerated();
      })
      .catch(err => {
        console.error(err);
      });
  }

  const clearHistory = () => {
    panelHistory.current = [];
  }

  const createUntitledDeck = () => {
    const deckData = {
      name: 'Untitled Deck',
    };

    return packDeck.create(currentPackIdRef.current, deckData);
  }

  const getCreateMethodLabel = () => {
    return CREATE_METHOD_LABELS[createMethod.current];
  }

  const getDeckName = () => {
    if (newDeckName.current) {
      return newDeckName.current;
    }

    if (createMethod.current == 'generateFromTopic') {
      return inputTopicData.current?.topic || null;
    }
    
    if (inputMethod.current == 'file') {
      const inputFileName = inputBufferData.current?.fileName || '';

      if (!inputFileName) {
        return null;
      }

      return inputFileName.split('.').slice(0, -1).join('.');
    }

    return null;
  }

  const getDeckOptions = (decks) => {
    return decks.map(deck => {
      return {
        id: deck.deckId,
        label: deck.name,
      };
    });
  }

  const getDefaultParserName = () => {
    if (!createMethod.current) {
      return null;
    }

    return CREATE_METHOD_DEFAULT_PARSER_NAMES[createMethod.current] || null;
  }

  const isAiParsable = () => {
    if (inputMethod.current === 'text') {
      return true;
    }

    const fileName = inputBufferData.current?.fileName || '';

    if (!fileName) {
      return false;
    }

    const fileExtension = FileHelper.getExtension(fileName);

    if (fileExtension && AI_PARSABLE_FILE_TYPES[createMethod.current]?.includes(fileExtension)) {
      return true;
    }

    return false;
  }

  const isCurrentPanelBackable = () => {
    return panelHistory.current.at(-1).isBackable;
  }

  const navigateToNextPanel = (nextPanelName) => {
    if (HISTORY_PANELS.includes(nextPanelName)) {
      pushPanelState(nextPanelName);
    }

    setCurrentPanel(nextPanelName);
  }

  const parseInputBufferData = (inputBufferData) => {
    let parseService = null;
    let isAiParser = true;

    switch (parserName.current) {
      case 'standardParseList':
        parseService = StandardParseListService;
        trackEvent(getCreateMethodLabel(), 'analyze', {name: inputBufferData.fileName});
        isAiParser = false;
      break;
      case 'aiParseList':
        parseService = AiParseListService;
        trackEvent(getCreateMethodLabel(), 'fix_with_ai');
      break;
      case 'aiParseDocument':
        parseService = AiParseDocumentService;
        trackEvent(getCreateMethodLabel(), 'analyze', {name: inputBufferData.fileName});
      break;
    }

    if (!parseService) {
      return handleProcessingError(new Error('No parser specified'));
    }

    if (isAiParser) {
      isAiParseProcessing.current = true;
      navigateToNextPanel('loading');
    }

    parseService.parse(inputBufferData)
      .then(parsedCardRows => {
        setHasInvalidInput(false); 
        setIsProcessing(false);
        isAiParseProcessing.current = false;
        cardRows.current = parsedCardRows;

        if (isAiParser) {
          handleAiParseCompleted();
        } else {
          takePanelSnapshot();
          navigateToNextPanel('review');
        }
      })
      .catch(err => {
        handleProcessingError(err);
      });
  }

  const parseInputTopicData = (inputTopicData) => {
    const body = {
      card_ct: inputTopicData.cardCount,
      a_lang: inputTopicData.answerLanguage,
      q_lang: inputTopicData.questionLanguage,
      topic: inputTopicData.topic,
    };

    trackEvent(getCreateMethodLabel(), 'generate');
    isAiParseProcessing.current = true;
    navigateToNextPanel('loading');

    AiParseTopicService.parse(body)
      .then(parsedCardRows => {
        setHasInvalidInput(false); 
        setIsProcessing(false);
        isAiParseProcessing.current = false;
        cardRows.current = parsedCardRows;
        takePanelSnapshot();
        navigateToNextPanel('aiReview');
      })
      .catch(err => {
        handleProcessingError(err);
      });
  }

  const popPanelState = () => {
    if (panelHistory.current.length == 0) {
      return null;
    }

    if (!panelHistory.current.at(-1)?.isBackable) {
      return null;
    }

    panelHistory.current.pop();

    return panelHistory.current[panelHistory.current.length - 1];
  }

  const pushPanelState = (panelName=null) => {
    if (!panelName) {
      return false;
    }

    panelHistory.current.push({
      isBackable: (BACKABLE_PANELS.includes(panelName) && panelHistory.current.length > 0),
      name: panelName,
    });

    return true;
  }

  const resetInputData = () => {
    cardRows.current = [];
    generatedDeckMetadata.current = {};
    inputBufferData.current = {};
    inputMethod.current = '';
    inputUrl.current = '';
    inputText.current = '';
    inputTopicData.current = {};
    newDeckDesc.current = '';
    newDeckName.current = '';
  }

  const takePanelSnapshot = () => {
    const currentPanelState = panelHistory.current.at(-1);

    currentPanelState.cardRows = cardRows.current;
    currentPanelState.createMethod = createMethod.current;
    currentPanelState.generatedDeckMetadata = generatedDeckMetadata.current;
    currentPanelState.inputBufferData = inputBufferData.current;
    currentPanelState.inputText = inputText.current;
    currentPanelState.inputTopicData = inputTopicData.current;
    currentPanelState.inputUrl = inputUrl.current;
    currentPanelState.newDeckDesc = newDeckDesc.current;
    currentPanelState.newDeckName = newDeckName.current;
  }

  const tryPreviousPanel = () => {
    if (!isCurrentPanelBackable()) {
      return false;
    }

    const prevPanelState = popPanelState();

    if (prevPanelState) {
      cardRows.current = prevPanelState.cardRows;
      createMethod.current = prevPanelState.createMethod;
      generatedDeckMetadata.current = prevPanelState.generatedDeckMetadata;
      inputBufferData.current = prevPanelState.inputBufferData;
      inputText.current = prevPanelState.inputText;
      inputTopicData.current = prevPanelState.inputTopicData;
      inputUrl.current = prevPanelState.inputUrl;
      newDeckDesc.current = prevPanelState.newDeckDesc;
      newDeckName.current = prevPanelState.newDeckName;

      setCurrentPanel(prevPanelState.name);
      return true;
    }

    return false;
  }

  
  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  if (isModalOpen) {

    return (
      <MakeFlashcardsModal
        acceptedFileTypes={ACCEPTED_FILE_TYPES[createMethod.current]}
        cardRows={cardRows.current}
        createMethod={createMethod.current}
        currentPanel={currentPanel}
        currentParserName={parserName.current}
        currentUser={currentUser}
        deckOptions={deckOptions.current}
        errorMessage={errorMessage.current}
        generatedDeckMetadata={generatedDeckMetadata.current}
        hasInvalidInput={hasInvalidInput}
        initialNewDeckDesc={newDeckDesc.current}
        initialNewDeckName={newDeckName.current}
        initialInputText={inputText.current}
        initialInputTopicData={inputTopicData.current}
        isAiParsable={isAiParsable()}
        isBackable={BACKABLE_PANELS.includes(currentPanel) && panelHistory.current.length > 1}
        isDirectToCards={isDirectToCards.current}
        isProcessing={isProcessing}
        isAiParseComplete={isAiParseComplete.current}
      />
    );
  }

  return null;
}

export default MakeFlashcardsModalController;
