
const TEMP_TEXT_FILE = 'PastedText.txt';

const ArrayBufferService = {

  acceptFile(filePointer) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
    
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;

        resolve({
          arrayBuffer: arrayBuffer,
          fileName: filePointer.name,
          mimeType: filePointer.type,
        });
      };
    
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      // begin reading the fileData as an ArrayBuffer and when it completes, the reader.onload above will be called
      reader.readAsArrayBuffer(filePointer);
    });
  },
  
  acceptText(text) {
    return new Promise((resolve, reject) => {
      try {
        const mimeType = 'text/plain';
        const arrayBuffer = new TextEncoder().encode(text).buffer; // Convert text to ArrayBuffer
            
        resolve({
          arrayBuffer: arrayBuffer,
          fileName: TEMP_TEXT_FILE,
          mimeType: mimeType,
        });
      } catch (err) {
        reject(err);
      }
    });
  },
};

export default ArrayBufferService;
