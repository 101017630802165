
import Modal                     from '_views/shared/Modal';
import PackModel                 from '_legacy-models/PackModel';
import PermissionsTooltip        from '_views/shared/PermissionsTooltip';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import RadioButtonsField         from '_views/shared/RadioButtonsField';
import RoundCheckbox             from '_views/shared/RoundCheckbox';
import StringHelper              from '_utils/StringHelper';
import TextareaField             from '_views/shared/TextareaField';

import {toClassStr} from '_utils/UiHelper';

const SCHOOL_LICENSE_FORM_URL = 'https://share.hsforms.com/1qLB8v_qTQS-gNXihGtFriA1fype';

class SharePackByEmailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasInvalidInput       : false,
      isProcessing          : false,
      invitees              : '',
      sharePermission       : 'study',
      shouldNotifyByEmail   : true,
      validationMessage     : '',
    };

    /*
      this.props:
        addClasses,
        hasBackOption,
        isOpen,
        isProcessing,
        onBackRequest,
        onCloseRequest,
        onClosed,
        onEmailSent,
        pack,
        title,
    */

    this.Pack = new PackModel;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const title = this.props.title || "Share Class By E-mail Address";

    return (
      <Modal
        addClasses="share-pack-by-email-modal"
        isOpen={this.props.isOpen}
        onClosed={() => this.handleModalClosed()}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">{title}</div>

        <div className="modal-guidance">Type the email addresses of the people whose classes you wish to add this class to. (People without Brainscape accounts will still see this class when they first sign in.)</div>

        <TextareaField
          addClasses="share-pack-addresses"
          key="textarea1"
          value={this.state.invitees}
          placeholder="james_brown@makeitfunky.com, brainscape@isthafunkiest.info"
          onChange={(e) => this.handleInviteesChange(e)}
        />
        {this.renderBottomCaption()}

        <div className="share-parameters">

          <div className="permission-options">
            <RadioButtonsField 
              addClasses="share-permissions"
              name="sharePermission"
              value={this.state.sharePermission}
              buttons={this.getUserPermissionOptions()}
              isInline={true}
              onClick={(value) => this.handleSharePermissionClick(value)}
            />

            <PermissionsTooltip 
              isPackPrivate={this.props.pack.flags.isPrivate}
              tooltipPosition="top"
              isMobileViewportSize={this.props.isMobileViewportSize}
            />
          </div>

          {this.renderNotifyByEmailCheckbox()}
        </div>

        {this.renderValidationMessage()}

        <div className="modal-actions">
          {this.renderBackTextButton()}
          {this.renderResolveModalButton()}
        </div>
      </Modal>    
    );
  }

  renderBottomCaption() {
    return (
      <div className='bottom-caption'>
        <i className='ion-ios-lightbulb'></i>
        <span className='caption-tip'>Limit 500 emails per day or contact us for a
          <a className='caption-link' href={SCHOOL_LICENSE_FORM_URL} target='_blank' rel='nofollow noopener'>school license.</a>
        </span>
      </div>
    )
  }

  renderNotifyByEmailCheckbox() {
    if (this.props.pack.flags.isCertified) {
      return null;
    }

    return (
      <RoundCheckbox 
        addClasses="send-email-option"
        isChecked={this.state.shouldNotifyByEmail}
        prompt="Notify recipients by e-mail"
        name="sendEmailOption"
        onClick={() => this.handleSendEmailOptionClick()}
        value={this.state.shouldNotifyByEmail}
      />
    );
  }

  renderBackTextButton() {
    if (this.props.hasBackOption === false) {
      return null;
    }

    return (
      <div className="cancel-modal-text-button back" onClick={(e) => this.handleBackButtonClick(e)}>Back</div>
    );
  }

  renderResolveModalButton() {
    const buttonText = (!this.props.hasInvalidInput) ? 'Share' : 'Retry';
    const classes = toClassStr(['resolve-modal-button', (this.state.invitees == '') ? 'disabled' : '']);

     return (
      <PillButton
        addClasses={classes}
        isProcessing={this.state.isProcessing}
        label={buttonText}
        onClick={() => this.handleShareButtonClick()}
      />
    );
  }

  renderValidationMessage() {
    if (!this.state.hasInvalidInput) {
      return null;
    }

    return (
      <div className="validation-message">
        {this.state.validationMessage}
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackButtonClick(e) {
    e.stopPropagation();

    this.props.onBackRequest();
  }

  handleInviteesChange(e) {
    const inviteesText = e.target.value;

    this.setState({
      invitees: inviteesText,
    })
  }

  handleModalClosed() {
    if (this.props.onClosed) {
      this.props.onClosed();
    }
  }

  handleOptionalMessageChange(e) {
    const messageText = e.target.value;

    this.setState({
      optionalMessage: messageText,
    })
  }

  handleSendEmailOptionClick() {
    const shouldNotifyByEmail = !this.state.shouldNotifyByEmail;

    this.setState({
      shouldNotifyByEmail: shouldNotifyByEmail,
    });
  }

  handleSharePermissionClick(value) {
    this.setState({
      sharePermission: value,
    });
  }

  handleShareButtonClick() {
    if (this.state.invitees == '') {
      return false;
    }

    this.setState({
      isProcessing: true,
      hasInvalidInput: false,
      validationMessage: '',
    });

    const {validInviteesArr, isValid, validationMessage} = this.parseAndValidateInvitees();

    if (!isValid) {

      this.setState({
        isProcessing: false,
        hasInvalidInput: true,
        validationMessage: validationMessage,
      });

      return false;
    }

    this.sharePack(validInviteesArr);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getUserPermissionOptions() {
    const userPermissionOptions = [];

    userPermissionOptions.push({label: 'Full Study', value: 'study', className: 'study-option'});

    if (this.props.pack.permission == 'admin') {
      userPermissionOptions.push({label: 'Edit', value: 'edit', className: 'edit-option'});
      userPermissionOptions.push({label: 'Admin', value: 'admin', className: 'admin-option'});
    }

    return userPermissionOptions;
  }

  sharePack(validInviteesArr) {
    const sendEmail = (this.props.pack.flags.isCertified) ? true : this.state.shouldNotifyByEmail;

    this.Pack.share({
      reqData: {
        share: {
          emails      : validInviteesArr,
          pack_id     : this.props.pack.packId,
          permission  : this.state.sharePermission,
          send_email  : sendEmail,
        },
      },
      done: (data) => {
        if (this.state.shouldNotifyByEmail) {
          this.props.onEmailSent();      
        } else {
          this.props.onCloseRequest();      
        }
      },
      fail: (jqXHR, status) => {
        console.log('Something went wrong in share pack. jqXHR, status:', jqXHR, status);
        this.props.onCloseRequest();      
      }
    });
  }

  parseAndValidateInvitees() {
    const inviteesText = this.state.invitees;
    const inviteesArr = inviteesText.split(',').map(invitee => invitee.trim());
    let validInviteesArr = [];
    let invalidInviteesArr = [];
    let isValid = true;
    let validationMessage;

    for (let i=0; i < inviteesArr.length; i++) {
      if (StringHelper.isValidEmail(inviteesArr[i])) {
        validInviteesArr.push(inviteesArr[i]);
      } else {
        invalidInviteesArr.push(inviteesArr[i]);
      }
    }

    if (invalidInviteesArr.length > 0) {
      isValid = false;
      validationMessage = 'Some of the e-mail addresses appear to be invalid. Please check the following entries: ' + invalidInviteesArr.toString();
    }

    return {validInviteesArr, isValid, validationMessage};
  }
}

export default SharePackByEmailModal;
