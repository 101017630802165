
// substrate and utils
import EventManager                     from '@brainscape/event-manager';
import React, { useState, useEffect }   from 'react';

// sub-components
import RoundedButton                    from '_views/shared/RoundedButton';
import SvgIcon                          from '_views/shared/SvgIcon';

import { toClassStr }                   from '_utils/UiHelper';


const HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/115002567252-How-do-I-make-flashcards';


const SelectMethodPanel = ({
  isDirectToCards,
}) => {

  /*
  ==================================================
   INIT 
  ==================================================
  */

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  const handleTypeFlashcardsButtonClick = () => {
    triggerTypeFlashcardsRequest();
  }

  const handleImportListButtonClick = () => {
    triggerImportListRequest();
  }

  const handleSummarizeFromContentButtonClick = () => {
    triggerSummarizeFromContentRequest();
  }

  const handleGenerateFromTopicButtonClick = () => {
    triggerGenerateFromTopicRequest();
  }

  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerImportListRequest = () => {  
    EventManager.emitEvent('make-flashcards-modal:import-paste-flashcards-request', {});
  }

  const triggerTypeFlashcardsRequest = () => {  
    EventManager.emitEvent('make-flashcards-modal:type-flashcards-request', {});
  }

  const triggerSummarizeFromContentRequest = () => {  
    EventManager.emitEvent('make-flashcards-modal:summarize-from-content-request', {});
  }

  const triggerGenerateFromTopicRequest = () => {  
    EventManager.emitEvent('make-flashcards-modal:generate-from-topic-request', {});
  }
  
  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderBottomCaption = () => {
    return (
      <div className='bottom-caption'>
        <i className='ion-ios-lightbulb'></i>
        <span className='caption-tip'>You can always switch between manual and automatic creation once you are in Edit mode.</span>
        <a className='caption-link learn-more-link' href={HELP_ARTICLE_URL} target='_blank' rel='nofollow noopener'>Learn more.</a>
      </div>
    )
  }

  const renderGenerateFromTopicIcon = () => {
    return (
      <SvgIcon
        svgIconTag='generate-from-topic-icon'
      />
    )
  }

  const renderSummarizeFromContentIcon = () => {
    return (
      <SvgIcon
        svgIconTag='summarize-from-content-icon'
      />
    )
  }
  const renderImportListIcon = () => {
    return (
      <SvgIcon
        svgIconTag='custom-import-list-icon'

      />
    )
  }

  const renderPanelContent = () => {
    const title = isDirectToCards ? 'Import/Make Flashcards' : 'Add Flashcards';

    return (
      <>
        <div className='modal-title'>{title}</div>
        <div className='modal-sections'>

          <div className='modal-section primary'>
            <div className='modal-section-header'>
              Manual
            </div>

            <div className='modal-section-content primary-content'>
              <RoundedButton 
                addClasses='type-flashcards-rounded-button'
                label='Type Flashcards'
                bgColor='rounded-button-emphasized'
                size='large'
                iconClass='study-button-icon'
                onClick={handleTypeFlashcardsButtonClick}
                tooltipContent='Enter questions and answers using the classic editor'
                tooltipPosition='top'
              />
            </div>
          </div>

          <div className='divider'>— OR —</div>

          <div className='modal-section secondary'>
            <div className='modal-section-header new-feature'>
              AI-Powered
            </div>

            <div className='modal-section-content secondary-content'>
              <RoundedButton 
                addClasses='import-list-rounded-button'
                customIcon={renderImportListIcon()}
                label='Import/Paste Flashcards'
                bgColor='rounded-button-tertiary'
                size='medium'
                onClick={handleImportListButtonClick}
                tooltipContent='Paste, Type, or Upload existing Q&A list'
                tooltipPosition='top'
              />

              <RoundedButton 
                addClasses='generate-flashcards-rounded-button'
                customIcon={renderSummarizeFromContentIcon()}
                label='Summarize From Content'
                bgColor='rounded-button-tertiary'
                size='medium'
                onClick={handleSummarizeFromContentButtonClick}
                tooltipContent='Use AI to make cards from notes, documents, etc.'
                tooltipPosition='top'
              />

              <RoundedButton 
                addClasses='ai-flashcards-rounded-button'
                customIcon={renderGenerateFromTopicIcon()}
                label='Just Tell AI What I Want'
                bgColor='rounded-button-tertiary'
                size='medium'
                onClick={handleGenerateFromTopicButtonClick}
                tooltipContent='Let AI create cards on a topic of your choice'
                tooltipPosition='top'
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <>
      {renderPanelContent()}
      {renderBottomCaption()}
    </>
  );

}

export default SelectMethodPanel;
