
import EventManager       from '@brainscape/event-manager';
import PillButton         from '_views/shared/PillButton';
import DynamicTooltipIcon from '_views/shared/DynamicTooltipIcon';
import PropTypes          from 'prop-types';
import React              from 'react';

import {modalCount}       from '_views/shared/Modal';
import {toClassStr}       from '_utils/UiHelper';

const PT = {
  addClasses:                   PropTypes.string,
  face:                         PropTypes.string,
  isDisabled:                   PropTypes.bool,
  isFtse:                       PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  level:                        PropTypes.number,
  onConfidenceRating:           PropTypes.func,
  onFtseConfidenceButtonClick:  PropTypes.func,
  onShowQuestionRequest:        PropTypes.func,
  shouldPulse:                  PropTypes.bool,
  tooltipContent:               PropTypes.string,
  tooltipPosition:              PropTypes.string,
};

const PULSATION_DELAY = 250; // allows for mounting of component and entry/exit animation before commencement of pulsation


class ConfidenceButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPulsing: false,
    }

    this.pulsationTimeout = null;

    this.confidenceScaleElem = null;
    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.startKeydownMonitor();
    this.subscribeToEvents();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldPulse && this.props.shouldPulse) {
      this.invokePulsation();
    }
  }

  componentWillUnmount() {
    this.stopKeydownMonitor();
    this.clearTimeoutsAndIntervals();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    if (!this.props.isFtse) {
      return false;
    }

    this.events.addListener('ftse:pulse-confidence-buttons', this.handleFtsePulseConfidenceButtons);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const face = this.props.face;
    const level = this.props.level;

    const levelClass = (level) ? `level-${level}` : 'level-0';
    const pulseClass = this.state.isPulsing ? 'pulse' : '';
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';

    const classes = toClassStr(['confidence-buttons', levelClass, pulseClass, isDisabledClass]);

    return (
      <div className={classes} ref={elem => this.confidenceScaleElem = elem}>
        <div className='prompt-container'>
          <div className="prompt">How well did you know this?</div>
          {this.renderInfoButton()}
        </div>

        <div className="button-group" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          {this.renderConfidenceButton(1)}
          {this.renderConfidenceButton(2)}
          {this.renderConfidenceButton(3)}
          {this.renderConfidenceButton(4)}
          {this.renderConfidenceButton(5)}
        </div>
      </div>
    );
  }

  renderConfidenceButton(number) {
    return (
      <div className={`confidence-button level-${number}`} onClick={(e) => this.handleConfidenceButtonClick(e, number)}>
        <div className={`level level-${number}`} cyid={`level-${number}`}>{number}</div>
        {this.renderClarifier(number)}
      </div>
    );
  }

  renderClarifier(number) {
    if (number == 1) {
      return (<div className="clarifier">Not at all</div>);
    }

    if (number == 5) {
      return (<div className="clarifier">Perfectly</div>);
    }

    return null;
  }

  renderInfoButton() {
    return (
      <DynamicTooltipIcon 
        addClasses="study-mix-type-dialog-button"
        delay={500}
        hasDismissButton={true}
        heading="How to rate your confidence"
        body={this.renderPromptTooltipBody()}
        iconType="help"
        isMobileViewportSize={this.props.isMobileViewportSize}
        position="top"
      />
    )
  }

  renderPromptTooltipBody() {
    return (
      <p className='body-text'>Brainscape will repeat low-confidence cards more frequently, helping you to better attack your weaknesses.  Only rate a 5 if you are perfectly confident you'll never forget it!</p>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleConfidenceButtonClick = (e, rating) => {
    if (this.props.isDisabled) {
      return false;
    }
    
    e.stopPropagation();
    this.handleConfidenceRating(rating);
  }

  handleConfidenceRating = (rating) => {
    this.triggerStopAllSoundsRequest();

    // handle any FTSE messaging, then process rating
    if (this.props.isFtse && this.props.shouldPulse) {
      this.setState({
        isPulsing: false
      }, () => {
        // ftse: dismiss answer bubble, display cbr bubble, and set post cbr bubble action upstream
        const ftsePostCbrBubbleAction = () => this.props.onConfidenceRating(rating);
        this.props.onFtseConfidenceButtonClick(rating, ftsePostCbrBubbleAction);
      });
    } else {
      if (this.props.onConfidenceRating) {
        this.props.onConfidenceRating(rating);
      }
      
      this.triggerCardConfidenceRated(rating);
    }
  }

  handleKeyDown = (e) => {
    if (this.props.isDisabled) {
      return false;
    }

    if (modalCount() > 0) {
      return null;
    }
 
    // space bar
    if (e.keyCode == 32) {
      e.preventDefault();
      e.stopPropagation();

      this.triggerRevealCardFaceRequest('question');
    }

    // 1 key or numpad 1
    if (e.keyCode == 49 || e.keyCode == 97) {
      e.preventDefault();
      e.stopPropagation();

      this.handleConfidenceRating(1);
    }

    // 2 key or numpad 2
    if (e.keyCode == 50 || e.keyCode == 98) {
      e.preventDefault();
      e.stopPropagation();

      this.handleConfidenceRating(2);
    }

    // 3 key or numpad 3
    if (e.keyCode == 51 || e.keyCode == 99) {
      e.preventDefault();
      e.stopPropagation();

      this.handleConfidenceRating(3);
    }

    // 4 key or numpad 4
    if (e.keyCode == 52 || e.keyCode == 100) {
      e.preventDefault();
      e.stopPropagation();

      this.handleConfidenceRating(4);
    }

    // 5 key or numpad 5
    if (e.keyCode == 53 || e.keyCode == 101) {
      e.preventDefault();
      e.stopPropagation();

      this.handleConfidenceRating(5);
    }
  };

  handleMouseEnter = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }

  handleFtsePulseConfidenceButtons = () => {
    this.invokePulsation();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCardConfidenceRated(level) {
    EventManager.emitEvent('card-confidence:rated', {
      level: level,
    });
  }

  triggerRevealCardFaceRequest(face='question') {
    EventManager.emitEvent('smart-card:reveal-card-face-requested', {
      face: face,
    });
  }

  triggerStopAllSoundsRequest() {
    EventManager.emitEvent('sound-player:stop-all-request', {});
  }

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.confidenceScaleElem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.pulsationTimeout);
  }

  invokePulsation() {
    clearTimeout(this.pulsationTimeout);

    this.pulsationTimeout = setTimeout(() => {
      this.setState({
        isPulsing: true,
      });
      clearTimeout(this.pulsationTimeout);
    }, PULSATION_DELAY);
  }

  startKeydownMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  stopKeydownMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }
}

ConfidenceButtons.propTypes = PT;

export default ConfidenceButtons;
