
import EventManager from '@brainscape/event-manager';
import JobMonitor from '_utils/JobMonitor';
import SessionStorageHelper from '_utils/SessionStorageHelper';
import TimeHelper from '_utils/TimeHelper';

const PACK_DUPLICATION_TIMEOUT = 1000 * 60 * 15; // 15 minutes
const jobMonitors = new Map(); // Tracks active jobs

const PackDuplicationMonitorService = {

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleEventMessage(eventData) {
    if (!eventData?.meta?.src_pack_id) return false;

    const processTag = 'pack-duplication';
    const sourcePackId = eventData.meta.src_pack_id;
    const processKey = `${processTag}-${sourcePackId}`;
    const status = this.getPackDuplicationStatus(eventData);

    // Ensure there's a `JobMonitor` instance for this job
    if (!jobMonitors.has(processKey)) {
      jobMonitors.set(processKey, new JobMonitor(
        processKey,
        PACK_DUPLICATION_TIMEOUT,
        this.handleJobTimeout.bind(this)
      ));
    }

    // Update the job monitor
    jobMonitors.get(processKey).update();

    const processData = {
      key: processKey,
      kind: 'pack:dup',
      meta: eventData.meta,
      message: eventData?.msg || null,
      messageType: 'info',
      progressIndex: eventData.progressIndex || 0,
      progressTotal: eventData.progressTotal || 0,
      status: status,
      subLabel: eventData.stage,
    };

    if (status === "error") {
      processData.messageType = 'error';
      processData.message = 'Error duplicating class';
      processData.messageDetail = `${eventData.msg};\n\n Last reported stage: ${eventData.stage}`;
      processData.actionLinkLabel = 'Try again in 15 min or contact support';
      processData.actionLinkUrl = 'https://brainscape.zendesk.com/hc/en-us/requests/new';

      // cleanUp if job errors out
      this.cleanUpJob(processKey);
    }

    if (status === "complete") {
      processData.messageType = 'result';
      processData.message = this.getCompletionMessage(eventData);
      processData.actionLinkLabel = 'View Duplicated Class';
      processData.actionLinkUrl = `/l/dashboard/${eventData.meta?.dst_pack_id}/decks`;

      this.publishPackDuplicated({
        srcPackId: sourcePackId,
        srcPackName: eventData.meta?.src_pack_name,
        dstPackId: eventData.meta?.dst_pack_id,
      });

      // cleanUp after job completes
      this.cleanUpJob(processKey);
    }

    this.updateProcessLedger(processTag, sourcePackId, status, TimeHelper.isoDateTimeStamp());

    this.publishProcessUpdate(processTag, processKey, processData);
  },

  handleJobTimeout(processKey) {
    console.log(`Job ${processKey} has stalled!`);

    const processTag = 'pack-duplication';
    const processData = {
      key: processKey,
      kind: 'pack:dup',
      message: 'Pack duplication timeout',
      messageType: 'error',
      status: 'error',
      actionLinkLabel: 'Try again in 15 min or contact support',
      actionLinkUrl: 'https://brainscape.zendesk.com/hc/en-us/requests/new',
    }

    this.publishProcessUpdate(processTag, processKey, processData);
    this.publishPackDuplicationJobTimeout(processKey);
    this.cleanUpJob(processKey);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  cleanUpJob(processKey) {
    jobMonitors.get(processKey)?.stopMonitoring();
    jobMonitors.delete(processKey);
  },

  cleanUp() {
    jobMonitors.forEach((monitor) => monitor.stopMonitoring());
    jobMonitors.clear();
  },

  getCompletionMessage(eventData) {
    const packName = eventData?.meta?.src_pack_name || null;
    return packName ? `Duplication of "${packName}" completed` : 'Class duplication completed';
  },

  getPackDuplicationStatus(eventData) {
    if (eventData.error) return 'error';
    if (eventData.stoppedAt) return 'complete';
    if (eventData.startedAt == 0) return 'pending';

    return 'processing';
  },

  updateProcessLedger(processTag, sourcePackId, status, updatedAt) {
    const processLedger = SessionStorageHelper.getItem('processLedger') || {};

    if (!processLedger[processTag]) {
      processLedger[processTag] = {};
    }

    processLedger[processTag][sourcePackId] = {
      status: status,
      updatedAt: updatedAt,
    };

    SessionStorageHelper.setItem('processLedger', processLedger);
  },


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishPackDuplicated(eventData) {
    EventManager.emitEvent('pack:duplicated', eventData);
  },

  publishPackDuplicationJobTimeout(processKey) {
    EventManager.emitEvent('pack-duplication:job-timeout', {
      processKey: processKey,
    });
  },

  publishProcessUpdate(processTag, processKey, processData) {
    EventManager.emitEvent(`${processTag}:process-update`, {
      processTag: processTag,
      processKey: processKey,
      processData: processData,
    });
  },
};

export default PackDuplicationMonitorService;
