
import { apiPost } from '_core/Api2';
import DelayedResultsHandler from '_utils/DelayedResultsHelper';
import TextExtractionService from '_services/TextExtractionService';


const PARSING_TIME_LIMIT = (1000 * 60 * 3.5); // 3.5 minutes

const EXTRACTED_TEXT_SIZE_LIMIT = 100 * 1024;; // 100 KB

const TEXT_MIME_TYPES = ['text/plain', 'text/html', 'text/xml', 'application/xml', 'application/json', 'public.plain-text', '.txt', '.html', '.xml', '.json'];


const AiParseDocumentService = {

  parse(inputBufferData) {
    if (TEXT_MIME_TYPES.includes(inputBufferData.mimeType)) {
      return this.parseText(inputBufferData);
    } else {
      return this.extractAndParseText(inputBufferData);
    }
  },
  
  extractAndParseText(inputBufferData) {
    return TextExtractionService.extract(inputBufferData)
      .then((extractedText) => {  
        // Convert the extracted text back into an ArrayBuffer
        const textEncoder = new TextEncoder();
        const newArrayBuffer = textEncoder.encode(extractedText).buffer;
  
        // Update inputBufferData with the new ArrayBuffer
        inputBufferData.arrayBuffer = newArrayBuffer;
  
        // Continue with processing the updated inputBufferData
        return this.parseText(inputBufferData);
      })
      .catch((err) => {
        console.error('Error in TextExtractionService.extract:', err);
        return Promise.reject(err);
      });
  },
  
  parseText(inputBufferData) {
    const body = inputBufferData.arrayBuffer;

    if (body.byteLength > EXTRACTED_TEXT_SIZE_LIMIT) {
      return Promise.reject(new Error('Too much content; please break it up into smaller pieces.'));
    }
  
    const params = {
      mimeType: 'text/plain',
      body: new TextDecoder().decode(body),
    };
  
    return apiPost('ai/document', params)
      .then((rs) => {  
        return DelayedResultsHandler.handleDelayedResults(rs, PARSING_TIME_LIMIT)
          .then((delayedResult) => {
            if (delayedResult && delayedResult.cards) {
              return delayedResult.cards;
            } else {
              return Promise.reject(new Error('No cards found in the result.'));
            }
          })
          .catch((err) => {
            console.error('Error in DelayedResultsHandler:', err);
            return Promise.reject(err);
          });
      })
      .catch((err) => {
        console.error('Error in apiPost:', err);
        return Promise.reject(err);
      });
  },
};

export default AiParseDocumentService;
