
const FileHelper = {

  getExtension(fileName) {
    if (!fileName) { return null; }

    return `.${fileName.split('.').pop()}`;
  },
}

export default FileHelper;
