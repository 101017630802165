
import EventManager                     from '@brainscape/event-manager';
import ButtonInfoWrapper                from '_views/shared/ButtonInfoWrapper';
import PillButton                       from '_views/shared/PillButton';
import React                            from 'react';
import SvgIcon                          from '_views/shared/SvgIcon';


const SummarizeFromContentPanel = ({
  acceptedFileTypes,
  currentUser,
}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleGotItButtonClick = () => {
    triggerCloseModalRequest();
  }

  const handlePasteTextButtonClick = () => {
    triggerPasteTextRequest();
  };

  const handleUploadFileButtonClick = () => {
    triggerUploadFileRequest();
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerCloseModalRequest = () => { 
    EventManager.emitEvent('make-flashcards-modal:close', {});
  }
  
  const triggerPasteTextRequest = () => {
    EventManager.emitEvent('summarize-from-content-panel:paste-text-request', {});
  }

  const triggerUploadFileRequest = () => {
    EventManager.emitEvent('summarize-from-content-panel:upload-file-request', {});
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS  
  ==================================================
  */

  const renderIsPendingMessaging = () => {
    const featureOsUrl = currentUser.featureOsUrl || 'https://brainscape.featureos.app';

    return (
      <>
        <div className='modal-guidance'>Feature coming soon!</div>

        <div className="modal-story">
          <div className="modal-story-text">
            In Brainscape’s <a href="https://www.brainscape.com/get-mobile-app" target="_blank">mobile app</a>, you can already upload notes and lessons and use AI to transform them into flashcards.  This feature is coming soon to our website!  Please feel free to <a className="features-forum-button" href={featureOsUrl} target="_blank">upvote it here</a>, or add some comments about how you’d like it to work. 
          </div>
        </div>
        <div className='modal-actions'>
          <PillButton
            addClasses='resolve-modal-button'
            label='Ok'
            onClick={handleGotItButtonClick}
          />
        </div>

      </>
    );
  }

  const renderModalContent = () => {
    if (!currentUser.flags?.features?.include_summarize_from_content) {
      return renderIsPendingMessaging();
    }

    return renderStandardFunctionality();
  }

  const renderStandardFunctionality = () => {
    return (
      <>
        <div className='modal-guidance'>How would you like to summarize your flashcards?</div>
        <div className='modal-actions'>
          <ButtonInfoWrapper
            additionalText='(Notes, Lesson, etc.)'
          >
            <PillButton
              addClasses='resolve-modal-button'
              label='Paste Text'
              onClick={handlePasteTextButtonClick}
            />
          </ButtonInfoWrapper>

          <ButtonInfoWrapper
            additionalText={`(${acceptedFileTypes?.join(', ')})`}
          >
            <PillButton
              addClasses='resolve-modal-button'
              label='Upload File'
              onClick={handleUploadFileButtonClick}
            />
          </ButtonInfoWrapper>
        </div>
      </>
    );
  }

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <div className="summarize-from-content-panel">

      <div className='modal-image summarize-from-content'>
        <SvgIcon
          svgIconTag="summarize-from-content-icon"
          color={'tertiary'}
        />
      </div>
      
      <div className='modal-title'>Summarize from Content</div>
      {renderModalContent()}
    </div>
  );
}

export default SummarizeFromContentPanel;
