
import model from '_core/newModel';

const cardListMetadata = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returnks promises)
  ==================================================
  */

  create(cardRows) {
    const path = `ai/cards/metadata`;

    const data = {
      cardRows: cardRows,
    };

    return model.create(path, data);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default cardListMetadata;
