
import EventManager                             from '@brainscape/event-manager';
import StatusBar                                from '_views/shared/StatusBar';
import React, { useState, useEffect, useRef }   from 'react';


const StatusBarController = ({
  defaultPosition,
  isMobileViewportSize,
  sidebarMode,
}) => {  
  /*
  ==================================================
   INIT 
  ==================================================
  */

  const eventManager = new EventManager();
  let _isMounted = false;
  let removeProcessTimeout = null;


  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(defaultPosition);
  const [processes, setProcesses] = useState({});

  const processesRef = useRef({});
  

  
  // on Mount
  useEffect(() => {
    _isMounted = true;
    clearTimeoutsAndIntervals();

    /* SUBSCRIBE TO EVENTS */
    eventManager.addListener('status-bar:close-request', handleCloseRequest);
    eventManager.addListener('status-bar:disable-process-request', handleDisableProcessRequest);    
    eventManager.addListener('status-bar:open-request', handleOpenRequest);
    eventManager.addListener('status-bar:remove-process-request', handleRemoveProcessRequest);    
    eventManager.addListener('status-bar:update-request', handleUpdateRequest);

    // on Unmount
    return () => {
      eventManager.disable();
      clearTimeoutsAndIntervals();
      _isMounted = false;
    };
  }, []);


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleCloseRequest = () => {
    closeStatusBar();
  };

  const handleOpenRequest = () => {
    setIsOpen(true);
  };

  const handleDisableProcessRequest = (eventData) => {
    const processKey = eventData.processKey;
    if (!processKey) {
      return false;
    }

    const updatedProcesses = {...processesRef.current};
    const updatedProcess = updatedProcesses[processKey];

    updatedProcess.isDisabled = true;
    updatedProcesses[processKey] = updatedProcess;

    processesRef.current = updatedProcesses;

    setProcesses(processesRef.current);
  }

  const handleRemoveProcessRequest = (eventData) => {
    const processKey = eventData.processKey;
    if (!processKey) {
      return false;
    }

    const updatedProcesses = {...processesRef.current};
    delete updatedProcesses[processKey];

    processesRef.current = updatedProcesses;

    setProcesses(processesRef.current);

    if (Object.keys(updatedProcesses).length === 0) {
      closeStatusBar();
    }
  }

  const handleUpdateRequest = (eventData) => {
    if (!eventData) {
      return false;
    }

    const {processKey, processData} = eventData;

    if (processesRef.current[processKey]?.isDisabled) {
      return false;
    }

    updateProcess(processKey, processData);
  };
  

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const clearTimeoutsAndIntervals = () => {
    clearTimeout(removeProcessTimeout);
  };

  const closeStatusBar = () => {
    setIsOpen(false);
  }

  const updateProcess = (processKey, processData) => {
    const currentProcess = processesRef.current[processKey] || {
      isShowing: true,
    };

    const updatedProcess = {...currentProcess, ...processData};
    const updatedProcesses = {...processesRef.current};
        
    updatedProcesses[processKey] = updatedProcess;

    processesRef.current = updatedProcesses;
    setProcesses(processesRef.current);

    if (updatedProcess.isShowing) {
      setIsOpen(true);
    }
  };

  
  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <StatusBar
      initialPosition={defaultPosition}
      isMobileViewportSize={isMobileViewportSize}
      isOpen={isOpen}
      processes={processes}
      sidebarMode={sidebarMode}
      transitionDuration={1000} 
    />
  );
}

export default StatusBarController;
