
const StringHelper = {

  // courtesy of https://www.30secondsofcode.org/js/s/string-case-conversion/
  convertCase(str, toCase = 'camel') {
    if (!str) return '';

    const delimiter =
      toCase === 'snake'
        ? '_'
        : toCase === 'kebab'
          ? '-'
          : ['title', 'sentence'].includes(toCase)
            ? ' '
            : '';

    const transform = ['camel', 'pascal'].includes(toCase)
      ? x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()
      : ['snake', 'kebab'].includes(toCase)
        ? x => x.toLowerCase()
        : toCase === 'title'
          ? x => x.slice(0, 1).toUpperCase() + x.slice(1)
          : x => x;

    const finalTransform =
      toCase === 'camel'
        ? x => x.slice(0, 1).toLowerCase() + x.slice(1)
        : toCase === 'sentence'
          ? x => x.slice(0, 1).toUpperCase() + x.slice(1)
          : x => x;

    const words = str.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    );

    return finalTransform(words.map(transform).join(delimiter));
  },

  isAlphanumeric(str) {
    const pattern = /^[0-9a-zA-Z]+$/;
    return pattern.test(str);
  },

  isBlank(str) {
    return (!str || !str.trim());
  },

  isValidEmail(email) {
    const pattern = /\S+@\S+\.\S+/;
    return pattern.test(email);
  },

  isWhitespace(str) {
    return !(str && str.trim());
  },

  safeDecode(str) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return doc.documentElement.textContent;
  },

  simpleFormat(str) {
    str = str.replace(/\r\n?/, "\n");
    str = $.trim(str);
    if (str.length > 0) {
      str = str.replace(/\n\n+/g, '</p><p>');
      str = str.replace(/\n/g, '<br />');
      str = '<p>' + str + '</p>';
    }
    return str;
  },

  toCamelCase(str) {
    return this.convertCase(str, 'camel');
  },

  toKebabCase(str) {
    return this.convertCase(str, 'kebab');
  },

  toKebobCase(str) {
    return this.toKebabCase(str);
  },

  toPascalCase(str) {
    return this.convertCase(str, 'pascal');
  },

  toSentenceCase(str) {
    return this.convertCase(str, 'sentence');
  },

  toSlug(str) {
    return this.convertCase(str, 'kebab');
  },

  toSnakeCase(str) {
    return this.convertCase(str, 'snake');
  },

  toTitleCase(str) {
    return this.convertCase(str, 'title');
  },

  upperCaseFirst([first, ...rest], locale = navigator.language) {
    return [first.toLocaleUpperCase(locale), ...rest].join('');
  },
};

export default StringHelper;
