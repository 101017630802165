
import React              from 'react';
import {toClassStr} from '_utils/UiHelper';


const data = [
  {
    icon: 'ion-ios-infinite',
    heading: 'Study unlimited flashcards',
    subHeading: 'Find Brainscape-certified or cards made by educators & other top users',
  },
  {
    icon: 'ion-ios-color-wand',
    heading: 'Make flashcards with AI',
    subHeading: 'Automate the creation of hundreds of flashcards in seconds',
  },
  {
    icon: 'ion-image',
    heading: 'Use advanced authoring tools',
    subHeading: 'Add images & sounds; access formatting, sorting, & copying options',
  },
  {
    icon: 'ion-android-bookmark',
    heading: 'Bookmark Flashcards',
    subHeading: 'Mark particular flashcards for later focused study (app only)',
  },
  {
    icon: 'ion-ios-copy',
    heading: 'Copy other users\' flashcards',
    subHeading: 'Make your own copy & repurpose for your own study needs',
  },
  {
    icon: 'ion-loop',
    heading: 'Reverse flashcards (A/Q)',
    subHeading: 'Study the "back" of a flashcard before the front',
  },
  {
    icon: 'ion-stats-bars',
    heading: 'See PRO student learning stats',
    subHeading: 'Track how often PRO students or colleagues are studying',
  },
  {
    icon: 'ion-android-hand',
    heading: 'Make your classes private',
    subHeading: 'Restrict access to other Pro users you invite directly',
  },
  {
    icon: 'ion-android-refresh',
    heading: 'Reset study stats',
    subHeading: 'Reset your confidence ratings & give yourself a clean slate',
  },
];

const reactElementsArray = data.map((data, i) => {
  const classes = toClassStr(['feature', data.className]);
  const iconClasses = toClassStr(['feature-icon', data.icon]);

  return (
    <li key={i} className={classes}>
      <i className={iconClasses}></i>
      <div className='feature-title'>{data.heading}</div>
      <div className='feature-desc'>{data.subHeading}</div>
    </li>
  );
});


class FeaturesList extends React.Component {
  constructor(props) {
    super(props);
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <>
        {reactElementsArray}
      </>
    );
  }
  
  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


}

export default FeaturesList;
