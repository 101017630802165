
// substrate and utils
import EventManager           from '@brainscape/event-manager';
import PropTypes              from 'prop-types';
import React                  from 'react';
import SessionStorageHelper   from '_utils/SessionStorageHelper';
import Tracker                from '_utils/Tracker';
import UiHelper               from '_utils/UiHelper';
import {toClassStr}           from '_utils/UiHelper';

// models
import packDuplicate          from '_models/packDuplicate';
import userPack               from '_models/userPack';

// sub-components
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';


const PT = {      
  addClasses:                   PropTypes.string,
  currentPack:                  PropTypes.object,
  currentUser:                  PropTypes.object,
  iconType:                     PropTypes.string, 
  initialOptionsOpenState:      PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  onSelectPackRequest:          PropTypes.func,
  tooltipContent:               PropTypes.string,
  tooltipPosition:              PropTypes.string,
};

const MENU_OPTIONS_HASH = {
  importMakeFlashcards: {id: 'importMakeFlashcards', tag: 'import-make-flashcards', label: 'Import/Make Flashcards', onlyDeckEditor: true, onlyPro: false, notMobileViewport: true, inMakeFlashcardsButtonFeature: true,},
  exportCsv: {id: 'exportCsv', tag: 'export-csv', label: 'Export CSV', onlyPackAdmin: true, onlyIfHasDecks: true, onlyPro: false},
  duplicatePack: {id: 'duplicatePack', tag: 'duplicate-pack', label: 'Duplicate Class', paywall: 'duplicate_class', featuresList: 'list-6', onlyPro: true, onlyDuplicatable: true},
  removePack: {id: 'removePack', tag: 'remove-pack', label: 'Remove from your Classes', },
  separator: {id: '---', label: null},
  makeClassPrivate: {id: 'makeClassPrivate', tag: 'make-class-private', label: 'Make Class Private', onlyPackAdmin: true, onlyIfPublic: true, onlyPro: true, paywall: 'privacy', featuresList: 'list-3'},
  makeClassPublic: {id: 'makeClassPublic', tag: 'make-class-public', label: 'Make Class Public', onlyPackAdmin: true, onlyIfPrivate: true, onlyPro: true, paywall: 'privacy'},
  previewPublicPage: {id: 'previewPublicPage', tag: 'preview-public-page', label: 'Preview Public Class Page'},
};


class SidebarPackOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areDecksSortable: false,
      categoryHash: {},
      deckCards: [],
      deckName: '',
      dialogOpts: {},
      isProcessingOptionAction: false,
      menuOptions: [],
    };
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.updateUserOptions();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.currentPack.permission != prevProps.currentPack.permission) ||
      (this.props.currentPack.flags?.isPrivate != prevProps.currentPack.flags?.isPrivate)
    ) {
      this.updateUserOptions();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['pack-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          addClasses="no-option-icons"
          menuOptions={this.state.menuOptions}
          iconType={this.props.iconType}
          initialOpenState={this.props.initialOptionsOpenState}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.currentUser.flags?.isPro}
          hasLegacyStyles={false}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition='bottomLeft'
          shouldButtonTurn={false}
          tooltipContent={this.props.tooltipContent}
          tooltipPosition={this.props.tooltipPosition}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    let requiresPro = MENU_OPTIONS_HASH[optionId].onlyPro;

    if (requiresPro && !this.props.currentUser.flags?.isPro) {
      let desiredAction = MENU_OPTIONS_HASH[optionId].label;
      let paywall = MENU_OPTIONS_HASH[optionId].paywall;
      let featuresList = MENU_OPTIONS_HASH[optionId].featuresList;
      
      this.showUpgradeModal(desiredAction, paywall, featuresList);

    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'importMakeFlashcards':
        this.performImportMakeFlashcardsAction();
      break;
      case 'exportCsv':
        this.performExportCsvAction();
      break;
      case 'duplicatePack':
        this.performDuplicatePackAction();
      break;
      case 'makeClassPrivate':
        this.performMakeClassPrivateAction();
      break;
      case 'makeClassPublic':
        this.performMakeClassPublicAction();
      break;
      case 'removePack':
        this.performRemovePackAction();
      break;
      case 'previewPublicPage':
        this.performPreviewPublicPageAction();
      break;
    }
  }

  performDuplicatePackAction() {
    this.setState({
      isProcessingOptionAction: true,
    });

    const processLedger = SessionStorageHelper.getItem('processLedger') || {};
    const packDuplicationProcesses = processLedger['pack-duplication'] || {};
    const currentPackDuplicationRecord = packDuplicationProcesses[this.props.currentPack.packId];

    if (currentPackDuplicationRecord) {
      const currentPackDuplicationStatus = currentPackDuplicationRecord.status;

      if (currentPackDuplicationStatus != 'complete') {
        const timeSinceLastUpdate = Date.now() - new Date(currentPackDuplicationRecord.updatedAt).getTime();

        if (timeSinceLastUpdate < (10 * 60 * 1000)) {
          this.triggerInfoModalOpen({
            title: 'Duplication in Progress',
            message: 'The duplication process for this class is already in progress. Please check back soon.',
            resolveButtonText: 'Got it',
            onCloseRequest: () => {
              this.setState({
                isProcessingOptionAction: false,
              });
            }
          });

          return false;
        }
      }
    }

    packDuplicate.create(this.props.currentPack.packId).then(() => {
      this.setState({
        isProcessingOptionAction: false,
      });
    }).catch((err) => {
      console.error('something went wrong attempting to duplicate pack. err:', err);
      this.setState({
        isProcessingOptionAction: false,
      });
    });
  }

  performExportCsvAction() {
    this.setState({
      isProcessingOptionAction: true,
    });

    UiHelper.openInNewTab(this.props.currentPack.paths.exportPath);

    this.triggerInfoModalOpen({
      title: 'Class Exported',
      message: "Check your browser's downloads folder for a CSV of your class",
      resolveButtonText: 'Got it',
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performImportMakeFlashcardsAction() {
    this.props.onSelectPackRequest(this.props.currentPack.packId);
    this.triggerImportMakeFlashcardsModalOpen();
  }

  performMakeClassPrivateAction() {
    this.props.onSelectPackRequest(this.props.currentPack.packId);
    this.triggerEditPackPrivacyModalOpen(true);
  }

  performMakeClassPublicAction() {
    this.props.onSelectPackRequest(this.props.currentPack.packId);
    this.triggerEditPackPrivacyModalOpen(false);
  }

  performPreviewPublicPageAction() {
    const url = `${this.props.currentPack.paths.sharePreviewLink}?preview=true`;
    UiHelper.openInNewTab(url, '_blank');
  }

  performRemovePackAction = () => {
    this.setState({
      isProcessingOptionAction: true,
    });

    const actionText = `remove the ${this.props.currentPack.name} class from your library`;

    this.triggerConfirmModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove class',
      onResolution: () => {
        userPack.remove(this.props.currentPack.packId);
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerEditPackPrivacyModalOpen(newPrivacyState) {
    EventManager.emitEvent('edit-pack-privacy-modal:open', {
      newPrivacyState         : newPrivacyState,
      pack                    : this.props.currentPack,
    });
  }

  triggerEditPackPrivacyModalClose() {
    EventManager.emitEvent('edit-pack-privacy-modal:close', {});
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerImportMakeFlashcardsModalOpen = () => { 
    EventManager.emitEvent('make-flashcards-modal:open', {
      deckId: null,
      isDirectToCards: true,
      packId: this.props.currentPack.packId,
    });
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      featuresList:  featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  showUpgradeModal(desiredAction, paywall, featuresList) {
    if (!paywall) {
      if (desiredAction == 'Export CSV') { paywall = 'csv_export_pack'; }
    }
    Tracker.trackPaywallWarning(paywall);
    this.triggerUpgradeModalOpen(desiredAction, paywall, featuresList);
  }

  updateUserOptions() {
    const currentPack = this.props.currentPack;
    const currentUser = this.props.currentUser;
    const isInMakeFlashcardsButtonFeature = !!(currentUser.flags?.features?.ai_phase_2 && currentUser.flags?.features?.include_make_flashcards_button)

    let menuOptionsForUser = Object.keys(MENU_OPTIONS_HASH).reduce((accumulator, menuOption) => {
      let permitPerEditDeckRights = (MENU_OPTIONS_HASH[menuOption].onlyDeckEditor) ? currentPack.flags?.areDecksEditable : true;
      let permitPerBscAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyBscAdmin) ? currentUser.flags?.isBscAdmin : true;
      let permitPerPackAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyPackAdmin) ? currentPack.permission == 'admin' : true;
      let permitPerPackOrBscAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyPackOrBscAdmin) ? (currentPack.permission == 'admin' || currentUser.flags?.isBscAdmin) : true;
      let permitPerDecksExistence = (MENU_OPTIONS_HASH[menuOption].onlyIfHasDecks) ? currentPack.stats.deckCount > 0 : true;
      let permitPerNonEditRights = (MENU_OPTIONS_HASH[menuOption].onlyNonEditor) ? !currentPack.flags?.areDecksEditable : true;
      let permitPerDuplicateRights = (MENU_OPTIONS_HASH[menuOption].onlyDuplicatable) ? currentPack.flags?.isDuplicatable : true;
      const permitPerPackIsPrivate = (MENU_OPTIONS_HASH[menuOption].onlyIfPrivate) ? currentPack.flags?.isPrivate : true;
      const permitPerPackIsPublic = (MENU_OPTIONS_HASH[menuOption].onlyIfPublic) ? !currentPack.flags?.isPrivate : true;
      const permitPerNotMobileViewport = (MENU_OPTIONS_HASH[menuOption].notMobileViewport) ? !this.props.isMobileViewportSize : true;
      const permitPerInMakeFlashcardsFeature = (MENU_OPTIONS_HASH[menuOption].inMakeFlashcardsButtonFeature) ? isInMakeFlashcardsButtonFeature : true;


      if (permitPerEditDeckRights && permitPerBscAdminRights && permitPerPackAdminRights && permitPerPackOrBscAdminRights && permitPerDecksExistence && permitPerNonEditRights && permitPerDuplicateRights &&permitPerPackIsPrivate && permitPerPackIsPublic && permitPerNotMobileViewport && permitPerInMakeFlashcardsFeature) {
        accumulator.push(MENU_OPTIONS_HASH[menuOption]);
      }
      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

SidebarPackOptionsButton.propTypes = PT;

export default SidebarPackOptionsButton;
