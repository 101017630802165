
import { apiPost } from '_core/Api2';
import DelayedResultsHandler from '_utils/DelayedResultsHelper';


const PARSING_TIME_LIMIT = (1000 * 60 * 3.5); // 3.5 minutes


const AiCardListMetadataService = {
  generate: function(cardRows) {

    const body = cardRows.slice(0, 50);

    const params = {
      mimeType: 'text/plain',
      body: JSON.stringify(body),
    };

    return apiPost('ai/cards/metadata', params)
      .then(function(rs) {

        return DelayedResultsHandler.handleDelayedResults(rs, PARSING_TIME_LIMIT)
          .then(function(delayedResult) {
            if (delayedResult && delayedResult.metadata) {
              return delayedResult.metadata;
            } else {
              return Promise.reject(new Error('No metadata found in the result.'));
            }
          })
          .catch(function(err) {
            return Promise.reject(err);
          });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },
};

export default AiCardListMetadataService;
