
import model from '_core/newModel';
import {waitForJob} from '_core/Api2';


const packDuplicate = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId) {
    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/duplicates`;

        model.create(path).then(data => {
          const jobData = {
            jobId: data?.job?.id || null,
            packId: packId,
          };

          model.publish('pack-duplication:job-created', jobData);

          resolve(jobData);
        }).catch(err => {
          console.error('something went wrong during pack duplicate job create. err:', err);
          reject(err);
          });
      } catch(err) {
        console.error('something went wrong during pack duplicate job create. err:', err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */
};

export default packDuplicate;
