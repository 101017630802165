
// Only handles fade-in (pairs with useFadeOutTransition).
import { useState, useEffect, useRef } from "react";

export function useFadeTransition(isVisible, duration = 500, onFadeOutComplete) {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isFadingIn, setIsFadingIn] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      requestAnimationFrame(() => setIsFadingIn(true));
    } else {
      setIsFadingIn(false);
      setTimeout(() => {
        setShouldRender(false);
        if (onFadeOutComplete) onFadeOutComplete();
      }, duration);
    }
  }, [isVisible, duration, onFadeOutComplete]);

  return {
    ref,
    style: {
      opacity: isFadingIn ? 1 : 0,
      transition: `opacity ${duration}ms ease`,
    },
    shouldRender,
  };
}
