
import { useCallback } from "react";

/**
 * A custom hook to merge multiple refs into a single ref callback.
 * This allows multiple refs (e.g., from different hooks) to be assigned to the same element.
 *
 * @param {...React.Ref} refs - Any number of refs (ref objects or ref functions).
 * @returns {React.RefCallback} - A callback ref function that assigns the element to all provided refs.
 */

const useMergedRef = (...refs) => {
  return useCallback((element) => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(element);
      } else {
        ref.current = element;
      }
    });
  }, refs);
};

export { useMergedRef };
