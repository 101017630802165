
import EventManager           from '@brainscape/event-manager';
import PropTypes              from 'prop-types';
import React                  from 'react';
import Spinner                from '_views/shared/Spinner';

import {
  CloseButton,
  EditButton,
}                             from '_views/shared/IconButton';

import {toClassStr}           from '_utils/UiHelper';


const PT = {
  acceptedFileMimeTypes:      PropTypes.array,
  backgroundImageUrl:         PropTypes.string,
  addClasses:                 PropTypes.string,
  emptyImageFlag:             PropTypes.string,
  fileMediaType:              PropTypes.string, // image, audio, csv, other
  fileSizeLimit:              PropTypes.number,
  inputElemId:                PropTypes.string,
  hasEditButton:              PropTypes.bool,
  hasRemoveButton:            PropTypes.bool,
  isProcessing:               PropTypes.bool,
  onBadFileTypeRequest:       PropTypes.func,
  onExcessFileSizeRequest:    PropTypes.func,
  onFileInputChange:          PropTypes.func,
  onFileLoaded:               PropTypes.func,
  onFileRemoved:              PropTypes.func,
  onFileRemoveRequest:        PropTypes.func,
  tooltipContent:             PropTypes.node,
  tooltipPosition:            PropTypes.string,  
};

const DEFAULT_FILE_SIZE_LIMIT =  5242880; // 5MB
const DEFAULT_ACCEPTABLE_FILE_MIME_TYPES = {
  image:  ['image/gif', 'image/png', 'image/jpeg'],
  audio:  ['audio/mpeg'],
  csv:    ['text/csv'],
};


class UploadableFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.elem = null;
    this.fileInputLabelElem = null;
    this.removeFileButtonElem = null;
    this.editFileButtonElem = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const acceptableFileMimeTypes = this.props.acceptedFileMimeTypes || DEFAULT_ACCEPTABLE_FILE_MIME_TYPES[this.props.fileMediaType];

    const inputElemId = this.props.inputElemId || 'input-elem';

    const style = (this.props.backgroundImageUrl) ? {backgroundImage: `url(${this.props.backgroundImageUrl})`} : null;

    const hasEmptyImageFlagClass = (!this.props.backgroundImageUrl && this.props.emptyImageFlag) ? 'has-empty-image-flag' : '';
    const isProcessingClass = (this.props.isProcessing) ? 'is-processing' : '';

    const classes = toClassStr(['uploadable-file', hasEmptyImageFlagClass, isProcessingClass, this.props.addClasses]);

    return (
      <div 
        className={classes}
        data-empty-image-flag={this.props.emptyImageFlag}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={(elem => this.elem = elem)}
        style={style}
      >

        <label ref={(elem => this.fileInputLabelElem = elem)} className='file-input-label' htmlFor={inputElemId}></label>

        <input
          className="file-input"
          id={inputElemId}
          accept={acceptableFileMimeTypes}
          name="file-input"
          onChange={this.handleFileInputChange}
          type='file'
        />

        {this.renderRemoveButton()}
        {this.renderEditButton()}

        {this.renderSpinner()}
      </div>
    );
  }

  renderRemoveButton = () => {
    if (!this.props.hasRemoveButton) {
      return null;
    }

    const tooltipContent = `Remove this ${this.props.fileMediaType || 'file'}`;

    return (
      <CloseButton
        addClasses="remove-file-button"
        onClick={this.handleRemoveFileButtonClick}
        tooltipContent={tooltipContent}
        tooltipPosition="bottom"
      />
    );
  }

  renderEditButton = () => {
    if (!this.props.hasEditButton) {
      return null;
    }

    const defaultTooltipContent = `Update this ${this.props.fileMediaType || 'file'}`;
    const tooltipContent = this.props.customTooltipContent ? this.props.customTooltipContent : defaultTooltipContent;

    return (
      <EditButton
        addClasses="edit-file-button"
        onClick={this.handleEditFileButtonClick}
        tooltipContent={tooltipContent}
        tooltipPosition="bottom"
      />
    );
  }

  renderSpinner = () => {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBadFileTypeRequest = () => {
    if (this.props.onBadFileTypeRequest) {
      this.props.onBadFileTypeRequest();
      return false;
    }

    this.triggerBadMediaTypeModalOpen();
  }

  handleExcessFileSizeRequest = () => {
    if (this.props.onExcessFileSizeRequest) {
      this.props.onExcessFileSizeRequest();
      return false;
    }

    this.triggerFileTooLargeModalOpen();
  }

  handleFileInputChange = (e) => {
    if (this.props.onFileInputChange) {
      this.props.onFileInputChange(e, (e) => this.processFile(e));
      return true;
    }

    this.processFile(e);
  };

  handleFileUploadError = (err) => {
    if (this.props.onFileUploadError) {
      this.props.onFileUploadError(err);
      return false;
    }

    this.triggerFileUploadErrorToastOpen();
  }

  handleMouseEnter = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen({
        content: this.props.tooltipContent,
        elem: this.elem,
        position: this.props.tooltipPosition || 'top',
      });
    }
  } 

  handleMouseLeave = () => {
    this.triggerTooltipClose();
  }

  handleEditFileButtonClick = () => {
    this.fileInputLabelElem.click();
  }

  handleRemoveFileButtonClick = () => {
    if (this.props.onFileRemoveRequest) {
      this.props.onFileRemoveRequest();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerBadMediaTypeModalOpen = () => {
    let validFormatsMessage = 'Valid formats- Image: JPG, PNG, GIF. Audio: MP3. Text: CSV.';

    if (this.IS_SVG_ENABLED) {
      validFormatsMessage = 'Valid formats- Image: JPG, PNG, GIF, SVG. Audio: MP3, Text: CSV.';
    }

    this.triggerInfoModalOpen({
      title: 'Unsupported File Type',
      message: validFormatsMessage,
      resolveButtonText: 'Got it',
    });
  }

  triggerFileUploadErrorToastOpen = (err) => {
    const message = `Problem uploading file. Err: ${err}`;
    this.triggerToastOpen(message, 'error');
  }

  triggerFileTooLargeModalOpen = () => {
    this.triggerInfoModalOpen({
      message: 'Files must be less than 5MB',
      title:   'File Too Large',
      resolveButtonText: 'Got it',
    });
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerInfoModalClose(viewProps) {
    EventManager.emitEvent('info-modal:close', viewProps);
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type, duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  processFile = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = this.props.fileSizeLimit || DEFAULT_FILE_SIZE_LIMIT;

    if (file.size > fileSizeLimit) {
      this.handleExcessFileSizeRequest(fileSizeLimit);
      return false;
    }

    const acceptableFileMimeTypes = this.props.acceptedFileMimeTypes || DEFAULT_ACCEPTABLE_FILE_MIME_TYPES[this.props.fileMediaType];

    if (acceptableFileMimeTypes.indexOf(file.type) == -1) {
      this.handleBadFileTypeRequest(acceptableFileMimeTypes);
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onabort = () => this.handleFileUploadError(reader.error);
    reader.onerror = () => this.handleFileUploadError(reader.error);
    reader.onloadend = () => {
      if (this.props.onFileLoaded) {
        this.props.onFileLoaded(reader.result);
      }
    };
  }
}

UploadableFile.propTypes = PT;

export default UploadableFile;
