
import EventManager from '@brainscape/event-manager';
import PackDuplicationMonitorService from '_services/PackDuplicationMonitorService';

import { createSubscription, removeSubscription } from '_core/Cable';

class UserStatusChannel {
  constructor(props) {
    this.props = props;
    this.events = new EventManager();
    this.subscription = null;
  }

  init = () => {
    this.subscription = createSubscription(
      { channel: 'UserStatusChannel' },
      { received: this.handleChannelUpdate }
    );

    console.log('UserStatusChannel initialized');
  };

  terminate = () => {
    removeSubscription(this.subscription);
    PackDuplicationMonitorService.cleanUp(); // Ensures job tracking is cleaned up
  };

  handleChannelUpdate = (eventData) => {
    if (eventData.userId !== this.props.currentUserId) return false;
    if (!eventData?.kind) return false;

    switch (eventData.kind) {
      case 'pack:dup':
        PackDuplicationMonitorService.handleEventMessage(eventData);
      break;

      default:
        console.warn(`UserStatusChannel: Unhandled event type ${eventData.kind}`);
      break;
    }
  };
}

export default UserStatusChannel;
