
import ebEvent from '_models/ebEvent';
import TimeHelper from '_utils/TimeHelper';

const BSA_EVENT_NAME = 'bsa:event'; // short for 'BrainScape Analytics event'
const GENERIC_EVENT_TYPE = 'ev';
const NAVIGATION_EVENT_TYPE = 'pv';  // short for 'page view'


const trackEvent = (category, action, tagData={}, shouldDebounce=true) => {
  const eventData = {
    event: GENERIC_EVENT_TYPE,
    path: `${category}/${action}`,
    at: new Date().getTime() / 1000,
    tags: tagData,
  };

  publish(BSA_EVENT_NAME, eventData, shouldDebounce);
};

const trackNavigation = (routeData, shouldDebounce=true) => {
  const routeName = parseName(routeData);
  const routeTags = parseTags(routeData);

  const eventData = {
    event: NAVIGATION_EVENT_TYPE,
    path: routeName,
    at: new Date().getTime() / 1000,
    tags: routeTags,
  };

  publish(BSA_EVENT_NAME, eventData, shouldDebounce);
};


/*
==================================================
  LOCAL UTILS
==================================================
*/

let EB_EVENTS = []; // "EB" is short for "EventBus"
let EB_EVENT_TIMER = null;

const parseName = (routeData) => {
  // TODO: replace this with a method that separates the base path from the query string
  return routeData?.name || 'unknown';
}

const parseTags = (routeData) => {
  // TODO: replace this with a method that generates a tags object from the query string, or from a passed-in tags object
  return routeData?.tags || {};
}

const publish = (event, data, shouldDebounce=true) => {
  const e = { event: event };
  if (data) e['data'] = data;

  EB_EVENTS.push(e);

  clearTimeout(EB_EVENT_TIMER);
  if (!shouldDebounce) {
    flush();
  } else {
    EB_EVENT_TIMER = setTimeout(flush, 150); // 150 is arbitrary
  }
};

const flush = () => {
  if (EB_EVENTS.length === 0) return;

  const events = EB_EVENTS;
  EB_EVENTS = [];

  console.log('EventBus Events:', events);

  ebEvent.create(events);
};


export {trackEvent, trackNavigation};
