
import EventManager             from '@brainscape/event-manager';
import MessagingHelper          from '_utils/MessagingHelper';
import PropTypes                from 'prop-types';
import React                    from 'react';
import RelatedSubjectsBanner    from '_views/shared/RelatedSubjectsBanner';
import UiHelper                 from '_utils/UiHelper';


const PT = {
  position:               PropTypes.string,
  block:                  PropTypes.string,
  userId:                 PropTypes.node,
};

class RelatedSubjectsBannerController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBannerContracting: false,
      isBannerOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */
  componentDidMount() {
    this.events.addListener(
      'related-subjects-banner:open',
      (data) => {
        this.handleOpenRequest(data);
      },
    );

    this.events.addListener(
      'related-subjects-banner:close',
      (data) => {
        this.handleCloseRequest();
      }
    );

    this.events.addListener(
      'related-subjects-banner:contract',
      (data) => {
        this.handleContractRequest();
      }
    );

    if (this.props.viewProps) {
      this.handleOpenRequest(this.props.viewProps);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.viewProps && this.props.viewProps) {
      this.handleOpenRequest(this.props.viewProps);
    }

    if (!prevProps.shouldClose && this.props.shouldClose) {
      this.handleCloseRequest();
    }
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let viewProps = {...this.state.viewProps};

    if (!viewProps) { 
      return null; 
    }

    return (
      <RelatedSubjectsBanner 
        addClasses={viewProps.addClasses}
        autoCloseDelay={viewProps.autoCloseDelay}
        block={viewProps.block}
        children={viewProps.children}
        isContracting={this.state.isBannerContracting}
        isOpen={this.state.isBannerOpen}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        onContracted={this.handleContracted}
        onContractRequest={this.handleContractRequest}
        html={viewProps.html}
        position={viewProps.position}
        relatedSubjects={viewProps.relatedSubjects}
        shouldAutoClose={viewProps.shouldAutoClose}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes banner closing animation. Flash message is still in DOM
  handleCloseRequest = () => {
    if (!this.state.viewProps) {
      return false;
    }

    this.publishBannerCloseRequested(this.state.viewProps);

    if (this.state.viewProps.id) {
      this.markBannerAsClosed(this.state.viewProps.id);
    }

    this.setState({
      isBannerOpen: false,
      isBannerContracting: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after banner close animation is complete. Removes message from DOM
  handleClosed = () => {
    if (this.state.viewProps && this.state.viewProps.position == "bottom") {
      UiHelper.showZendeskWidget();
    }

    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  // invokes banner close animation without marking banner as explicitly closed in local storage
  handleContractRequest = () => {
    this.publishBannerContractRequested(this.state.viewProps);

    this.setState({
      isBannerOpen: false,
      isBannerContracting: true,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onContractRequest) {
        this.state.viewProps.onContractRequest();
      }
    });
  }

  // invoked after banner contract animation is complete. Removes banner from DOM
  handleContracted = () => {
    if (this.state.viewProps && this.state.viewProps.position == "bottom") {
      UiHelper.showZendeskWidget();
    }

    const onContracted = this.state.viewProps.onContracted;

    this.setState({
      viewProps: null
    }, () => {
      if (onContracted) {
        onContracted();
      }
    });
  }

  handleOpenRequest = (data) => {
    if (this.isShowable(data)) {
      this.publishBannerOpenRequested(data);

      this.setState({
        isBannerOpen: true,
        isBannerContracting: false,
        viewProps: data
      }, () => {
        if (data.id) {
          this.markBannerAsSeen(data.id);
        }

        if (this.state.viewProps && this.state.viewProps.position == "bottom") {
          UiHelper.hideZendeskWidget();
        }
      });
    }
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishBannerCloseRequested(data) {
    EventManager.emitEvent('related-subjects-banner:close-requested', data);
  }

  publishBannerContractRequested(data) {
    EventManager.emitEvent('related-subjects-banner:contract-requested', data);
  }

  publishBannerOpenRequested(data) {
    EventManager.emitEvent('related-subjects-banner:open-requested', data);
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isShowable = (data) => {
    if (!data.id) {
      // this is most likely a client-side generated message providing feedback
      return true;
    }

    if (data.shouldOverrideHistory) {
      return true;
    }

    const bannerId = data.id;

    if (data.shouldRepeat) {
      const hasClosed = MessagingHelper.hasClosedBanner(this.props.userId, bannerId);      
      return !hasClosed;
    }

    const hasSeen = MessagingHelper.hasSeenBanner(this.props.userId, bannerId);

    return !hasSeen;
  }

  markBannerAsSeen = (bannerId) => {
    MessagingHelper.markBannerAsSeen(this.props.userId, bannerId);
  }

  markBannerAsClosed = (bannerId) => {
    MessagingHelper.markBannerAsClosed(this.props.userId, bannerId);
  }
}


RelatedSubjectsBannerController.propTypes = PT;

export default RelatedSubjectsBannerController;
