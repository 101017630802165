
import { apiPost } from '_core/Api2';
import DelayedResultsHandler from '_utils/DelayedResultsHelper';

const PARSER_NAME = 'aiParseList';
const PARSING_TIME_LIMIT = (1000 * 60 * 3.5); // 3.5 minutes


const AiParseListService = {
  parse: function(inputBufferData) {
    const body = inputBufferData.arrayBuffer;

    const params = {
      mimeType: 'text/plain',
      body: new TextDecoder().decode(body),
    };

    return apiPost('ai/card_list', params)
      .then(function(rs) {
        return DelayedResultsHandler.handleDelayedResults(rs, PARSING_TIME_LIMIT)
          .then(function(delayedResult) {
            if (delayedResult && delayedResult.cards) {
              return delayedResult.cards;
            } else {
              return Promise.reject(new Error('No cards found in the result.'));
            }
          })
          .catch(function(err) {
            err.parserName = PARSER_NAME;
            return Promise.reject(err);
          });
      })
      .catch(err => {
        err.parserName = PARSER_NAME;
        return Promise.reject(err);
      });
  },
};

export default AiParseListService;
