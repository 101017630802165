
import DynamicTooltipIcon               from '_views/shared/DynamicTooltipIcon';
import EventManager                     from '@brainscape/event-manager';
import PillButton                       from '_views/shared/PillButton';
import Pulldown                         from '_views/shared/Pulldown';
import React, { useState, useEffect }   from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';
import SvgIcon                          from '_views/shared/SvgIcon';
import TextField                        from '_views/shared/TextField';
import TypeHelper                       from '_utils/TypeHelper';

const ChooseDeckPanel = ({
  currentUser,
  deckOptions,
  generatedDeckMetadata,
  initialNewDeckDesc,
  initialNewDeckName,
  isProcessing,
}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [deckOption, setDeckOption] = useState(null);
  const [newDeckDesc, setNewDeckDesc] = useState(initialNewDeckDesc || '');
  const [newDeckName, setNewDeckName] = useState(initialNewDeckName || '');
  const [isDecksPulldownOpen, setIsDecksPulldownOpen] = useState(false);
  const [selectedDeckId, setSelectedDeckId] = useState(-1);
  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleCancelButtonClick = () => {
    if (!window.confirm('Are you sure you want to cancel? You will lose any cards you have generated.')) {
      return;
    }

    triggerCancelRequest();
  }

  const handleDecksPulldownButtonClick = () => {
    setIsDecksPulldownOpen(!isDecksPulldownOpen);
  }

  const handleDecksPulldownOptionClick = (deckId) => {
    setIsDecksPulldownOpen(false);
    setSelectedDeckId(deckId);
  }

  const handleDecksPulldownOutsideClick = () => {
    setIsDecksPulldownOpen(false);
  }

  const handleExistingDeckSubmit = () => {
    setDeckOption('existing');
    triggerExistingDeckSubmit();
  }

  const handleNewDeckMetadataFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (validateTextInput()) {
      setDeckOption('new');
      triggerNewDeckMetadataSubmit();
    }
  }

  const handleSuggestTitleAndDescButtonClick = () => {
    triggerSuggestDeckMetadataRequest();
  }

  const handleDeckDescChange = (e) => {
    setNewDeckDesc(e.target.value);
  }

  const handleDeckNameChange = (e) => {
    setNewDeckName(e.target.value);
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerCancelRequest = () => {
    EventManager.emitEvent('make-flashcards-modal:close', {});
  }

  const triggerExistingDeckSubmit = () => {
    EventManager.emitEvent('choose-deck-panel:existing-deck-submit', {
      deckId: selectedDeckId,
    });
  }

  const triggerNewDeckMetadataSubmit = () => {
    EventManager.emitEvent('choose-deck-panel:new-deck-metadata-submit', {
      newDeckDesc: newDeckDesc,
      newDeckName: newDeckName,
    });
  }

  const triggerSuggestDeckMetadataRequest = () => {
    EventManager.emitEvent('choose-deck-panel:suggest-deck-metadata-request', {});
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const validateTextInput = () => { 
    return true; 
  };


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderGeneratedDeckMetadata = () => {
    if (!currentUser.flags.isBscAdmin) {
      return null;
    }

    if (!generatedDeckMetadata) {
      return null;
    }

    if (TypeHelper.isEmptyObject(generatedDeckMetadata)) {
      return null;
    }

    return (
      <div className='generated-deck-metadata'>
        <div className='generated-deck-metadata-title'>BSC Admin info: AI Generated Deck SEO Metadata:</div>
        <div className='generated-deck-metadata-content'>
          <pre>{JSON.stringify(generatedDeckMetadata, null, 2)}</pre>
        </div>
      </div>
    );
  }

  const renderSuggestTitleAndDescButton = () => {
    return (
      <SimpleTextButton 
        addClasses="suggest-title-and-desc-button"
        customIcon={renderTwoSparklesButtonIcon()}
        label="Suggest a title and description"
        onClick={handleSuggestTitleAndDescButtonClick}
      />
    );
  }

  const renderTwoSparklesButtonIcon = () => {
    return (
      <SvgIcon
        svgIconTag="two-sparkles-icon"
        shouldInheritColor={true}
      />
    )
  }



  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <div className="choose-deck-panel">
      <div className="title-and-info-tooltip">
        <div className='modal-title'>Choose a deck for your new cards</div>
        <DynamicTooltipIcon 
          addClasses="copy-place-info-tooltip"
          body="Cards in Brainscape are grouped into decks. Choose an existing deck or create a new one into which to place your new cards."
          hasDismissButton={true}
          position="top"
        />
      </div>

      <div className="modal-sections">

        <div className="modal-section existing-deck-option">
          <div className='modal-section-header'>
            An Existing deck
          </div>

          <Pulldown
            addClasses='decks-pulldown'
            isOpen={isDecksPulldownOpen}
            options={deckOptions}
            fieldName='deck'
            placeholderText='Select destination deck...'
            selectedValue={selectedDeckId || '-1'}
            shouldSuppressNullOption={false}
            onButtonClick={handleDecksPulldownButtonClick}
            onOptionClick={handleDecksPulldownOptionClick}
            onOutsideClick={handleDecksPulldownOutsideClick}
          />

          <div className='modal-actions'>

            <PillButton
              addClasses='resolve-modal-button pill-button-emphasized'
              isDisabled={selectedDeckId == -1}
              isProcessing={isProcessing && deckOption == 'existing'}
              label='Add cards to this deck'
              onClick={handleExistingDeckSubmit}
            />

            <SimpleTextButton
              addClasses='cancel-modal-text-button'
              label='Cancel'
              onClick={handleCancelButtonClick}
            />
          </div>
        </div>

        <div className='divider'>— OR —</div>

        <div className="modal-section new-deck-option">
          <div className='modal-section-header'>
            A New Deck
          </div>

          <form className="new-deck-form" onSubmit={handleNewDeckMetadataFormSubmit}>
            <TextField      
              addClasses='new-deck-field deck-name'
              label='Title'
              hasInitialFocus={true}
              onChange={handleDeckNameChange}    
              placeholder="Name your deck"
              type='text'
              value={newDeckName}
            />
            <TextField      
              addClasses='new-deck-field deck-desc'
              label='Description' 
              onChange={handleDeckDescChange}    
              placeholder="Provide a brief summary (optional)"
              type='text'
              value={newDeckDesc}
            />
          </form>
      
          <div className='modal-actions'>

            <div className="first-row">
              {renderSuggestTitleAndDescButton()}
            </div>

            <div className="second-row">

              <PillButton
                addClasses='resolve-modal-button pill-button-emphasized'
                isDisabled={newDeckName.trim() === ''}
                isProcessing={isProcessing && deckOption == 'new'}
                label='Add Cards to New Deck'
                onClick={handleNewDeckMetadataFormSubmit}
              />

              <SimpleTextButton
                addClasses='cancel-modal-text-button'
                label='Cancel'
                onClick={handleCancelButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseDeckPanel;
