
import EventManager                     from '@brainscape/event-manager';
import PillButton                       from '_views/shared/PillButton';
import React, { useState, useEffect }   from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';

import { toClassStr }                   from '_utils/UiHelper';


const AiExceptionPanel = ({ exceptionType, errorMessage }) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleOkButtonClick = () => {
    triggerModalCloseRequest();
  }

  const handleNotNowButtonClick = () => {
    triggerModalCloseRequest();
  }

  const handleUpgradeButtonClick = () => {
    triggerUpgradeModalOpen();
    triggerModalCloseRequest();
  }

  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  const triggerModalCloseRequest = () => {
    EventManager.emitEvent('make-flashcards-modal:close', {});
  }

  const triggerUpgradeModalOpen = () => {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: 'AI tokens',
      paywall: 'ai',
      featuresList:  'list-4',
    });
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderErrorMessage = () => {
    if (errorMessage) {
      return (
        <div className='error-message'>{errorMessage}</div>
      )
    }

    return null;
  }

  const renderPanelContent = () => {
    switch (exceptionType) {
      case 'freeAiException':
        return (
          <>
            <div className='modal-title'>AI limit reached</div>
            <p className='modal-subtitle'>Upgrade to Brainscape Pro to access a much larger pool of AI tokens, as well as a ton of other great features!</p>
    
            <div className='modal-actions'>
              <SimpleTextButton
                addClasses='cancel-modal-text-button'
                label='Not now'
                onClick={handleNotNowButtonClick}
              />
        
              <PillButton
                addClasses='resolve-modal-button pill-button-emphasized'
                label='Upgrade'
                onClick={handleUpgradeButtonClick}
              />
            </div>
          </>
        )
      case 'proAiException':
        return (
          <>
            <div className='modal-title'>Daily AI limit reached</div>
            <p className='modal-subtitle'>Could not generate the card list: You have exceeded your daily AI token quota. Please try again tomorrow.</p>
    
            <div className='modal-actions'>
              <PillButton
                addClasses='cancel-modal-text-button'
                label='Ok'
                onClick={handleOkButtonClick}
              />
            </div>
          </>
        )
      default:
        return (
          <>
            <div className='modal-title'>AI Exception</div>
            <p className='modal-subtitle'>Could not generate the card list due to a temporary problem with AI. Please try again later.</p>

            {renderErrorMessage()}
    
            <div className='modal-actions'>
              <PillButton
                addClasses='cancel-modal-text-button'
                label='Ok'
                onClick={handleOkButtonClick}
              />
            </div>
          </>
        )
    }
  };


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['ai-exception-panel']);

  return (
    <div className={classes}>
      {renderPanelContent()}
    </div>
  )
}

export default AiExceptionPanel;
