
// Fades out & shrinks before removal.
export function useFadeOutAndShrinkTransition(isVisible, duration = 500, onFadeOutComplete) {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const ref = useRef(null);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setShouldRender(false);
        if (onFadeOutComplete) onFadeOutComplete();
      }, duration);
    }
  }, [isVisible, duration, onFadeOutComplete]);

  return {
    ref,
    style: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "scale(1)" : "scale(0)",
      transition: `opacity ${duration}ms ease, transform ${duration}ms ease`,
    },
    shouldRender,
  };
}
