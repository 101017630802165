
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';


const packDeck = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckData) {
    const path = `packs/${packId}/decks`;
    return model.create(path, deckData).then(deckData => {
      model.publish('deck:created', {
        deck: deckData.deck,
        deckId: deckData.deck.deckId,
        packId: packId,
        packStats: deckData.packStats,
      });
  
      return deckData;
    });
  },

  index(packId) {
    const path = `packs/${packId}/decks`;

    return new Promise((resolve, reject) => {
      try {
        model.index(path).then(res => {
          const deckIds = res.decks.map(deck => deck.deckId);
          const decksData = {
            decks: res.decks,
            deckIds: deckIds,
          };  

          resolve(decksData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  show(packId, deckId, isStudy=false) {
    const path = `packs/${packId}/decks/${deckId}`;

    return new Promise((resolve, reject) => {
      try {
        model.show(path, isStudy).then(res => {
          const deckData = {
            deck: res.deck,
          };  

          resolve(deckData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  update(packId, deckId, deckData) {
    const path = `packs/${packId}/decks/${deckId}`;
    return model.update(path, deckData).then(deck => model.publish('deck:updated', {
      packId: packId,
      deck: deck,
      deckId: deckId,
    }));
  },

  destroy(packId, deckId) {
    let path = `packs/${packId}/decks/${deckId}`;

    return new Promise((resolve, reject) => {
      try {
        model.destroy(path).then(res => {
          // delete succeeded because this deck was not in more than one pack

          model.publish('deck:removed', {
            packId: packId,
            deckId: deckId,
            packStats: res?.packStats || null,
          });

          resolve(res);
        }).catch(err => {
          // if we find ourselves here it may be because it is a deck that is in more than one pack, try to remove it instead
          if (err.message.indexOf('you do not have permission to delete this deck') != -1) { 
            // NOTE: This is a brittle test, the error reporting chain from server to browser needs some shoring up; we should also consider revising the error on the server end
            path = `packs/${packId}/decks/${deckId}/remove`;

            model.destroy(path).then(res => {

              model.publish('deck:removed', {
                packId: packId,
                deckId: deckId,
                packStats: res?.packStats || null,
              });

              resolve(res);
            }).catch(err => {
              throw err;
            });
          } else {
            reject(err);
          }
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   CACHING
  ==================================================
  */

  getCachedIndex(packId) {
    return SessionStorageHelper.getPackItem(packId, 'packDecks') || null;
  },

  getCachedItem(packId, deckId) {
    return SessionStorageHelper.getPackDeckItem(packId, deckId, 'packDeck') || null;
  },

  setCachedIndex(packId, packDecks) {
    SessionStorageHelper.setPackItem(packId, 'packDecks', packDecks);
  },

  setCachedItem(packId, deckId, packDeck) {
    SessionStorageHelper.getPackDeckItem(packId, deckId, 'packDeck', packDeck);
  },

  /*
  ==================================================
   CONVENIENCE METHODS
  ==================================================
  */

  isDuplicatable(user, pack) {
    // this can change if the user's permission level is changed via the Learners tab. mimics server side logic

    if (pack.flags.isCertified) {
      const isBscAdmin = user.flags.isBscAdmin;
      const isPackAdmin = pack.permission == 'admin';

      return (isBscAdmin && isPackAdmin);
    }

    if (pack.flags.isPrivate) {
      return user.flags.isPro && this.isEditable(user, pack);
    }

    return user.flags.isPro;
  },

  isEditable(pack) {
    return ['admin', 'edit'].indexOf(pack.permission != -1);
  },

  isLocked(user, pack, deckId) {
    if (user.flags.isPro) {
      return false;
    }

    const deckNumber = pack.deckIds.indexOf(deckId) + 1;
    const visibleLimit = Math.ceil(pack.deckIds.length * 0.1);

    return (deckNumber <= visibleLimit);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packDeck;
