import PropTypes          from 'prop-types';
import React              from 'react';
import TransitionWrapper  from './TransitionWrapper';
import { CloseButton }    from './IconButton';

import { toClassStr }     from '_utils/UiHelper';

const PT = {
  addClasses: PropTypes.string,
  autoContractDelay: PropTypes.number,
  html: PropTypes.string,
  id: PropTypes.node,
  isContracting: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
  onCloseRequest: PropTypes.func,
  onContractRequest: PropTypes.func,
  onContracted: PropTypes.func,
  position: PropTypes.string, // bottom (default) or top
  shouldAutoContract: PropTypes.bool,
  shouldOverrideHistory: PropTypes.bool, // will be shown regardless of history
  shouldPersistOnOutsideClick: PropTypes.bool, // will remain open even if outside click
  shouldRepeat: PropTypes.bool, // will be shown every time until user closes
};

class RelatedSubjectsBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldRender: this.props.isOpen,
    };

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.shouldPersistOnOutsideClick) {
      this.startOutsideClickMonitor();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        shouldRender: true,
      });
    }
  }

  componentWillUnmount() {
    this.stopOutsideClickMonitor();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.state.shouldRender) {
      return null;
    }

    const locationClass = (this.props.position || 'bottom');
    let classes = toClassStr(['banner', locationClass, this.props.addClasses]);

    return (
      <TransitionWrapper
        addClasses="banner-transition-wrapper"
        onTransitionedOut={() => this.handleTransitionedOut()}
        shouldTransitionIn={this.props.isOpen}
        shouldTransitionOut={!this.props.isOpen}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >
        <div className={classes} ref={(elem) => (this.elem = elem)}>
          {this.renderBody()}
          <CloseButton
            addClasses="close-modal-button"
            onClick={() => this.handleCloseButtonClick()}
          />
        </div>
      </TransitionWrapper>
    );
  }

  renderBody() {

    if (this.props.html) {
      return (
        <div
          className="message-html"
          dangerouslySetInnerHTML={{ __html: this.props.html }}
        >
        </div>
      );
    }

    return (
      <div className="banner-body">
        <div className="message">
          <div className="icon"></div>
          <div className="heading">You may prefer our related Brainscape-certified flashcards:</div>
        </div>
        <ul className="recommended-flashcards">
          {this.props.relatedSubjects.map((subject) => (
            <a key={subject.key} className="bcc-name" href={subject.url}>
              <strong>{subject.name}</strong> 
              <span> flashcards</span>
            </a>
          ))}
        </ul>
      </div>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseButtonClick = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };

  handleOutsideClick = (e) => {
    if (this.elem && this.elem.contains(e.target)) {
      return false;
    }

    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };

  handleTransitionedOut = () => {
    const onClosed = this.props.onClosed ? this.props.onClosed : () => {};
    const onContracted = this.props.onContracted
      ? this.props.onContracted
      : () => {};
    const onTransitionedOut = this.props.isContracting
      ? onContracted()
      : onClosed();

    this.setState(
      {
        shouldRender: false,
      },
      () => {
        if (onTransitionedOut) {
          onTransitionedOut();
        }
      },
    );
  };

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  startOutsideClickMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('click', this.handleOutsideClick);
    }
  };

  stopOutsideClickMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('click', this.handleOutsideClick);
    }
  };
}

RelatedSubjectsBanner.propTypes = PT;

export default RelatedSubjectsBanner;
