
import {LargeCtaButton}   from '_views/shared/PillButton';
import Bubble             from '_views/shared/Bubble';
import React              from 'react';

import {toClassStr} from '_utils/UiHelper';

class EarlyExitBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isQuitting: false,
      isResuming: false,
    };

    /*
      this.props:
        addClasses,
        onConfirmCloseStudyMix,
        onClosed,
        onResumeStudyRequest,
        shouldClose
    */

    this._isMounted = false;
    this.boundKeydownHandler = this.handleKeydown.bind(this);
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.activateKeyboardMonitor();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.deactivateKeyboardMonitor();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['early-exit-bubble', this.props.addClasses]);

    return (
      <Bubble
        addClasses={classes}
        expansionDelay={500}
        isEscapeKeyActive={true}
        onClose={() => this.props.onClosed()}
        shouldAutoContract={false}
        shouldClose={this.props.shouldClose}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <div className="early-exit-bubble-body">
          <p className="question">Are you sure you want to quit the round before hitting your goal?</p>
          <p className="aside">{this.renderAsideMessage()}</p>

          <div className="action-buttons">

            <LargeCtaButton
              addClasses="resume pill-button-transparent"
              isProcessing={this.state.isResuming}
              label="Resume"
              onClick={() => this.handleResumeStudyClick()}
              title="Hit [Esc] to dismiss and resume"
            />

            <LargeCtaButton
              addClasses="quit pill-button-transparent"
              isProcessing={this.state.isQuitting}
              label="Quit"
              onClick={() => this.handleBackToLibraryClick()}
              title="Hit [Space Bar] to confirm and quit"
              type="neutral"
            />
          </div>
        </div>
      </Bubble>
    );
  }

  renderAsideMessage() {
    if (this.props.roundStepIndex < 3) {
      return "You're just getting started...";
    }

    if (this.props.roundStepIndex > 6) {
      return "You only have a few cards left.";
    }

    return "You're doing great!";
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackToLibraryClick() {
    this.setState({
      isQuitting: true,
    }, () => {
      this.props.onConfirmCloseStudyMix();
    });
  }

  handleResumeStudyClick() {
    this.setState({
      isResuming: true,
    }, () => {
      this.props.onResumeStudyRequest();
    });
  }

  handleKeydown(e) {
    e.stopPropagation(); // prevents bubble phase handling altogether (see comment on activateKeyboardMonitor)
    const keyCode = e.keyCode;

    switch (keyCode) {
      case 27: // escape
        e.preventDefault();
        this.handleResumeStudyClick();
      break;
      case 32: // space bar
        e.preventDefault();
        this.handleBackToLibraryClick();
      break;
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  activateKeyboardMonitor() {
    // NOTE: true flag at end of call designates handler to fire during capture phase, thus firing before standard bubble phase handlers 
    document.addEventListener('keydown', this.boundKeydownHandler, true);
  }

  deactivateKeyboardMonitor() {
    document.removeEventListener('keydown', this.boundKeydownHandler, true);
  }
}

export default EarlyExitBubble;
